import styles from './styles.module.css';
import HamburgerButton from '../HamburgerButton/HamburgerButton';
import TransitionComponent from '../TransitionComponent/TransitionComponents';
import { Icon } from '../index';
import { Icons } from '../Icon/Icon';
import { Link } from 'react-router-dom';
import React from 'react';

const SelectionMenuBase = ({ children, active, setActive, hidden, info }: any) => {
    const toggleMenu = () => {
        setTimeout(() => {
            setActive(!active);
        });
    };

    const openMenu = () => {
        setActive(true);
    };

    return (
        <>
            <div className={`${styles.info} ${styles.infoSingle} ${active ? styles.hidden : ''}`}>{info}</div>

            <div className={`${styles.aside} ${active ? styles.active : ''} ${hidden ? styles.hidden : ''} `}>
                <div className={styles.header}>
                    <TransitionComponent inProp={active} duration={200} isAbsolute={true} zIndex={150}>
                        <HamburgerButton onClick={toggleMenu} active={active} />

                        <div className={styles.logo}>
                            <Link to={'/'}>
                                <img src={'/logo.svg'} alt="white-thesis" />
                            </Link>
                        </div>
                    </TransitionComponent>
                </div>

                <div className={styles.body} onClick={openMenu}>
                    <TransitionComponent inProp={!active} duration={200}>
                        <div className={styles.icons}>
                            {/*<div className={styles.icon}>*/}
                            <Icon icon={Icons.investor} />
                            <Icon icon={Icons.asset} />
                            <Icon icon={Icons.checksGroup} />
                            {/*</div>*/}
                        </div>
                    </TransitionComponent>

                    <TransitionComponent inProp={active} duration={200} isAbsolute={true} zIndex={150}>
                        <div className={styles.infoTop}>{info}</div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                                paddingLeft: 48,
                                width: 385,
                                maxWidth: '90%',
                            }}
                        >
                            {children}
                        </div>
                    </TransitionComponent>
                </div>
            </div>
        </>
    );
};

export default SelectionMenuBase;
