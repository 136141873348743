import TableHeader from '../components/TableHeader';
import { Tr } from '../components';

import { BooleanFormat, DescriptionFormat, PercentFormat } from '../formats';

import { useTableStore } from '../../../store/useTableStore';
import TrProvider from '../components/TrProvider';

const SD4Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd4 ? (
        <table>
            <TableHeader sdKey={'sd4'} />

            <tbody>
                <Tr featName={'Legal form'} dataKey={'sd4'} colKey={'legalForm'} />

                <Tr featName={'Fund regime'} dataKey={'sd4'} colKey={'fundRegime'} />

                <Tr featName={'Capital type'} dataKey={'sd4'} colKey={'capitalType'} />

                <Tr
                    featName={'Commitment structure'}
                    dataKey={'sd4'}
                    colKey={'commitmentStructure'}
                    formatFunc={BooleanFormat}
                />

                <Tr
                    featName={'Min share payment'}
                    dataKey={'sd4'}
                    colKey={'minSharePayment'}
                    // formatFunc={PercentFormat}
                />

                <TrProvider>
                    <Tr
                        featName={'Capital calls details'}
                        dataKey={'sd4'}
                        colKey={'capitalCallsDetails'}
                        formatFunc={DescriptionFormat}
                        progressCol={'capitalCallsDetailsBar'}
                    />
                </TrProvider>

                <Tr featName={'Redemptions'} dataKey={'sd4'} colKey={'redemptions'} formatFunc={BooleanFormat} />
                <TrProvider>
                    <Tr
                        featName={'Redemption details'}
                        dataKey={'sd4'}
                        colKey={'redemptionDetails'}
                        formatFunc={DescriptionFormat}
                    />
                </TrProvider>

                <Tr featName={'Dividends'} dataKey={'sd4'} colKey={'dividends'} formatFunc={BooleanFormat} />
                <TrProvider>
                    <Tr
                        featName={'Dividends details'}
                        dataKey={'sd4'}
                        colKey={'dividendsDetails'}
                        formatFunc={DescriptionFormat}
                    />
                </TrProvider>
                <Tr featName={'Required valuation frequency'} dataKey={'sd4'} colKey={'requiredValuationFrequency'} />
                <Tr featName={'Min redemption frequency'} dataKey={'sd4'} colKey={'minRedemptionFrequency'} />
            </tbody>
        </table>
    ) : null;
};

export default SD4Table;
