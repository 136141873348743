import { DiagramData, DiagramDataTableShortItem, DiagramDataTaxLabel, Value } from '../types/deal-taxes';

export interface Tip {
    tax: string;
    percent: number | string;
    value: number;
    fullComments: string;
    taxBase: string;
}

export interface NodeDataItem {
    stage?: string;
    tax?: string;
    rate?: string;
    comments?: string;
    taxesFormula?: string;
    amount?: string;
    value?: number;
    tip: Tip;
}

export type NodeData = NodeDataItem[];

const getTip = (item: DiagramDataTaxLabel): Tip => {
    return {
        tax: item.tax,
        percent: item.taxRate,
        value: item.taxAmount,
        fullComments: item.textComments,
        taxBase: item.taxBase,
    };
};

// TODO: create BoxId type
const getByLevelAndCategory = (data: DiagramData, boxId: string) => {
    const filtered = data?.taxLabel
        // @ts-ignore
        .filter((arr: DiagramDataTaxLabel | DiagramDataTaxLabel[]) =>
            Array.isArray(arr)
                ? arr.filter((i: DiagramDataTaxLabel) => i.boxId.toUpperCase() === boxId.toUpperCase()).length
                : arr.boxId.toUpperCase() === boxId.toUpperCase()
        )
        .flat();

    if (!filtered[0]) {
        return [];
    }

    return filtered;
};

const parseValue = (value: number): number => {
    return parseFloat(value.toFixed(2));
};

export const getV1F5 = (data: DiagramData): Value => {
    const defaultValue = data.nav.f5;
    return {
        value: defaultValue,
        exemptions: 'Initial Investment',
        amount: `$${defaultValue}`,
        highlight: true,
    };
};

export const getF8 = (data: DiagramData): Value => {
    return {
        value: parseValue(data.nav.f8),
    };
};

export const getF6 = (data: DiagramData): Value => {
    return {
        value: parseValue(data.nav.f6),
    };
};

export const getV2F7 = (data: DiagramData): Value => {
    return {
        value: parseValue(data.nav.f7),
        amount: `$${parseValue(data.nav.f7)}`,
        exemptions: 'Net asset',
    };
};

export const getV3 = (data: DiagramData): Value => {
    const v2f7 = getV2F7(data);

    return {
        value: parseValue((v2f7.value / 100) * 20),
        amount: `$${parseValue((v2f7.value / 100) * 20)}`,
        exemptions: 'Return to asset: 20%',
        highlight: true,
    };
};

export const getV4F10 = (data: DiagramData): Value => {
    return {
        value: parseValue(data.nav.f10),
        amount: `$${parseValue(data.nav.f10)}`,
        exemptions: 'Asset',
    };
};

export const getF9 = (data: DiagramData): Value => {
    return {
        value: parseValue(data.nav.f9),
    };
};

export const getV5F13 = (data: DiagramData): Value => {
    return {
        value: data.nav.f13,
        amount: `$${data.nav.f13}`,
        exemptions: 'Asset distribution',
        highlight: true,
    };
};

export const getF12 = (data: DiagramData): Value => {
    return {
        value: parseValue(data.nav.f12),
    };
};

export const getV6F11 = (data: DiagramData): Value => {
    return {
        value: parseValue(data.nav.f11),
        amount: `$${parseValue(data.nav.f11)}`,
        exemptions: 'Net distribution',
    };
};

export const getV7F16 = (data: DiagramData): Value => {
    return {
        value: parseValue(data.nav.f16),
        amount: `$${parseValue(data.nav.f16)}`,
        exemptions: 'Selling remaining asset',
        highlight: true,
    };
};

export const getF15 = (data: DiagramData): Value => {
    return {
        value: parseValue(data.nav.f15),
    };
};

export const getV8F14 = (data: DiagramData): Value => {
    return {
        value: parseValue(data.nav.f14),
        amount: `$${parseValue(data.nav.f14)}`,
        exemptions: 'Net sold',
    };
};

export const getT1 = (data: DiagramData): NodeData => {
    const [investmentInvestor] = getByLevelAndCategory(data, 't1');

    if (!investmentInvestor) {
        return [];
    }

    return [
        {
            tip: getTip(investmentInvestor),
        },
    ];
};

export const getT2 = (data: DiagramData): NodeData => {
    const [investmentFund] = getByLevelAndCategory(data, 't2');

    if (!investmentFund) {
        return [];
    }

    return [
        {
            tip: getTip(investmentFund),
        },
    ];
};

export const getT3 = (data: DiagramData): NodeData => {
    const [investmentAsset] = getByLevelAndCategory(data, 't3');

    if (!investmentAsset) {
        return [];
    }

    return [
        {
            tip: getTip(investmentAsset),
        },
    ];
};

export const getT4 = (data: DiagramData): NodeData => {
    const holdingAssets = getByLevelAndCategory(data, 't4');

    if (holdingAssets.length === 0) {
        return [];
    }

    const results: NodeData = [];

    for (const holdingAsset of holdingAssets) {
        results.push({
            tip: getTip(holdingAsset),
        });
    }

    return results;
};

export const getT5 = (data: DiagramData): NodeData => {
    const holdingAssets = getByLevelAndCategory(data, 't5');

    if (holdingAssets.length === 0) {
        return [];
    }

    const results: NodeData = [];

    for (const holdingAsset of holdingAssets) {
        results.push({
            tip: getTip(holdingAsset),
        });
    }

    return results;
};

export const getT6 = (data: DiagramData): NodeData => {
    const holdingAssets = getByLevelAndCategory(data, 't6');

    if (holdingAssets.length === 0) {
        return [];
    }

    const results: NodeData = [];

    for (const holdingAsset of holdingAssets) {
        results.push({
            tip: getTip(holdingAsset),
        });
    }

    return results;
};

export const getT7 = (data: DiagramData): NodeData => {
    const [distributionAsset] = getByLevelAndCategory(data, 't7');

    if (!distributionAsset) {
        return [];
    }

    return [
        {
            tip: getTip(distributionAsset),
        },
    ];
};

export const getT8 = (data: DiagramData): NodeData => {
    const [distributionAsset] = getByLevelAndCategory(data, 't8');

    if (!distributionAsset) {
        return [];
    }

    return [
        {
            tip: getTip(distributionAsset),
        },
    ];
};

export const getT9 = (data: DiagramData): NodeData => {
    const distributionAssets = getByLevelAndCategory(data, 't9');

    if (distributionAssets.length === 0) {
        return [];
    }

    const results: NodeData = [];

    for (const distributionAsset of distributionAssets) {
        results.push({
            tip: getTip(distributionAsset),
        });
    }

    return results;
};

export const getT10 = (data: DiagramData): NodeData => {
    const sellingAssets = getByLevelAndCategory(data, 't10');

    if (sellingAssets.length === 0) {
        return [];
    }

    const results: NodeData = [];

    for (const sellingAsset of sellingAssets) {
        results.push({
            tip: getTip(sellingAsset),
        });
    }

    return results;
};

export const getT11 = (data: DiagramData): NodeData => {
    const sellingAssets = getByLevelAndCategory(data, 't11');

    if (sellingAssets.length === 0) {
        return [];
    }

    const results: NodeData = [];

    for (const sellingAsset of sellingAssets) {
        results.push({
            tip: getTip(sellingAsset),
        });
    }

    return results;
};

export const getT12 = (data: DiagramData): NodeData => {
    const sellingAssets = getByLevelAndCategory(data, 't12');

    if (sellingAssets.length === 0) {
        return [];
    }

    const results: NodeData = [];

    for (const sellingAsset of sellingAssets) {
        results.push({
            tip: getTip(sellingAsset),
        });
    }

    return results;
};

export const getFundsData = (data: DiagramData, stage: string) => {
    const funds = data.popUpTable
        // @ts-ignore
        .filter((arr: DiagramDataTableShortItem | DiagramDataTableShortItem[]) =>
            Array.isArray(arr)
                ? arr.filter((item) => item.stage === stage && (item.boxLevel === 'fund' || item.boxLevel === 'fof'))
                      .length
                : arr.stage === stage && (arr.boxLevel === 'fund' || arr.boxLevel === 'fof')
        )
        .flat();

    return {
        fof: funds.find((item: DiagramDataTableShortItem) => item.boxLevel === 'fof'),
        fund: funds.find((item: DiagramDataTableShortItem) => item.boxLevel === 'fund'),
    };
};
