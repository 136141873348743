import { DescriptionFormat } from './index';

const BooleanFormat = (d: boolean | string, options: any = {}) => {
    if (d !== false && d !== true) return '-';

    const src = d === false ? '/icons/cross.svg' : '/icons/check-wt.svg';

    return (
        <>
            <img alt={''} src={src} />

            {options?.descriptionVal && <div> {DescriptionFormat(options.descriptionVal, {})} </div>}
        </>
    );
};

export default BooleanFormat;
