import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import axios from '../../utils/axios';
import styles from './legal-structures-template.module.css';
import { PdfTemplateGraphLayout, PdfTemplateLayout } from '../../components/PdfTemplate';
import SD1 from '../ComparisonTable/tables/SD1';
import SD2 from '../ComparisonTable/tables/SD2';
import SD3 from '../ComparisonTable/tables/SD3';
import SD4 from '../ComparisonTable/tables/SD4';
import SD5 from '../ComparisonTable/tables/SD5';
import SD6 from '../ComparisonTable/tables/SD6';
import SD9 from '../ComparisonTable/tables/SD9';
import SD10 from '../ComparisonTable/tables/SD10';
import SD11 from '../ComparisonTable/tables/SD11';
import SD12 from '../ComparisonTable/tables/SD12';
import SD13 from '../ComparisonTable/tables/SD13';
import SD14 from '../ComparisonTable/tables/SD14';
import ComparisonTableSectionProvider from '../ComparisonTable/ComparisonTableSectionProvider';
import LegalStructurePdfTable from './LegalStructurePdfTable';
import SD1Table from '../ComparisonTable/tables/SD1Table';
import SD2Table from '../ComparisonTable/tables/SD2Table';
import SD3Table from '../ComparisonTable/tables/SD3Table';
import SD4Table from '../ComparisonTable/tables/SD4Table';
import SD5Table from '../ComparisonTable/tables/SD5Table';
import SD6Table from '../ComparisonTable/tables/SD6Table';
import SD9Table from '../ComparisonTable/tables/SD9Table';
import SD10Table from '../ComparisonTable/tables/SD10Table';
import SD11Table from '../ComparisonTable/tables/SD11Table';
import SD12Table from '../ComparisonTable/tables/SD12Table';
import SD13Table from '../ComparisonTable/tables/SD13Table';
import SD14Table from '../ComparisonTable/tables/SD14Table';
import { ArchDiagram } from '../LegalStructures/components/ArchDiagram';
import { Tips } from '../LegalStructures/components/Tips';
import { LegalStructuresSelectedData } from '../LegalStructures/LegalStructures';
import { useDiagramStore } from '../../store/useDiagramStore';
import { TableDescriptionContext } from '../ComparisonTable/context';
import { useTableStore } from '../../store/useTableStore';
import { GraphTypes } from '../../types/general';

const LegalStructuresTemplate = () => {
    const [selectedData, setSelectedData] = useState<LegalStructuresSelectedData>({
        openEnded: false,
        corporate: false,
        noFixedCapital: false,
        selfManaged: false,
        ifEltif: false,
        assets: [],
        investors: [],
    });

    const [tableData, setTableData] = useTableStore((state) => [state.data, state.setTableData]);

    const [archDiagramData, setArchDiagramData] = useState<any>(null);

    useEffect(() => {
        // @ts-ignore
        setSelectedData(window.pdfData.selectedData);

        // @ts-ignore
        setTableData(window.pdfData.table);

        // wait for table a bit
        setTimeout(() => {
            // @ts-ignore
            setArchDiagramData(window.pdfData.arch);
        }, 1);
        // @ts-ignore
    }, [window.pdfData]);

    const TablesWrapper = ({ children }: any) => {
        const { setGlobalExpanded } = useContext(TableDescriptionContext);

        setGlobalExpanded(true);

        return <>{children}</>;
    };

    const Info = () => {
        return (
            <>
                <h3 className={styles.info}>
                    Best-fit <span className={styles.highlight}>legal structures</span> :
                </h3>
            </>
        );
    };

    return (
        <>
            <PdfTemplateGraphLayout
                tableInfo={<Info />}
                tips={<Tips />}
                comments={null}
                selectedData={selectedData}
                legends={true}
                graphType={GraphTypes.Legal}
            >
                {archDiagramData && (
                    <div
                        style={{
                            transform: 'scale(1.4) translateX(-100px) translateY(40px)',
                        }}
                    >
                        <ArchDiagram dataFull={archDiagramData} activeCategory={null} onDataChange={() => {}} />
                    </div>
                )}
            </PdfTemplateGraphLayout>

            <PdfTemplateLayout>
                <div className={styles.pageHeader}>
                    <h2>Legal structures: expanded view</h2>

                    <div className={'tables'}>
                        <SD1 />
                        <SD2 />
                        <SD3 />
                        <SD4 />
                        <SD5 />
                        <SD6 />
                        <SD9 />
                        <SD10 />
                        <SD11 />
                        <SD12 />
                        <SD13 />
                        <SD14 />
                    </div>
                </div>
            </PdfTemplateLayout>

            <PdfTemplateLayout invisible={true} />

            <ComparisonTableSectionProvider>
                <TablesWrapper>
                    {tableData && tableData.sd1 && (
                        <LegalStructurePdfTable index={1}>
                            <PdfTemplateLayout>
                                <div className={styles.pageHeader}>
                                    <h2>Legal & regulatory features</h2>
                                </div>
                                <SD1Table />
                            </PdfTemplateLayout>
                        </LegalStructurePdfTable>
                    )}

                    {tableData && tableData.sd2 && (
                        <LegalStructurePdfTable>
                            <PdfTemplateLayout>
                                <div className={styles.pageHeader}>
                                    <h2>Structuring features</h2>
                                </div>

                                <SD2Table />
                            </PdfTemplateLayout>
                        </LegalStructurePdfTable>
                    )}

                    {tableData && tableData.sd3 && (
                        <LegalStructurePdfTable>
                            <PdfTemplateLayout>
                                <div className={styles.pageHeader}>
                                    <h2>Dealings / redemptions</h2>
                                </div>

                                <SD3Table />
                            </PdfTemplateLayout>
                        </LegalStructurePdfTable>
                    )}

                    {tableData && tableData.sd4 && (
                        <LegalStructurePdfTable>
                            <PdfTemplateLayout>
                                <div className={styles.pageHeader}>
                                    <h2>Dealings / subscriptions</h2>
                                </div>

                                <SD4Table />
                            </PdfTemplateLayout>
                        </LegalStructurePdfTable>
                    )}

                    {tableData && tableData.sd5 && (
                        <LegalStructurePdfTable>
                            <PdfTemplateLayout>
                                <div className={styles.pageHeader}>
                                    <h2>Capital requirements</h2>
                                </div>

                                <SD5Table />
                            </PdfTemplateLayout>
                        </LegalStructurePdfTable>
                    )}

                    {tableData && tableData.sd6 && (
                        <LegalStructurePdfTable>
                            <PdfTemplateLayout>
                                <div className={styles.pageHeader}>
                                    <h2>Form of participation</h2>
                                </div>

                                <SD6Table />
                            </PdfTemplateLayout>
                        </LegalStructurePdfTable>
                    )}

                    {tableData && tableData.sd9 && (
                        <LegalStructurePdfTable>
                            <PdfTemplateLayout>
                                <div className={styles.pageHeader}>
                                    <h2>Costs and timing</h2>
                                </div>

                                <SD9Table />
                            </PdfTemplateLayout>
                        </LegalStructurePdfTable>
                    )}

                    {tableData && tableData.sd10 && (
                        <LegalStructurePdfTable>
                            <PdfTemplateLayout>
                                <div className={styles.pageHeader}>
                                    <h2>Privacy</h2>
                                </div>

                                <SD10Table />
                            </PdfTemplateLayout>
                        </LegalStructurePdfTable>
                    )}

                    {tableData && tableData.sd11 && (
                        <LegalStructurePdfTable>
                            <PdfTemplateLayout>
                                <div className={styles.pageHeader}>
                                    <h2>Tax features 1/2</h2>
                                </div>

                                <SD11Table />
                            </PdfTemplateLayout>
                        </LegalStructurePdfTable>
                    )}

                    {tableData && tableData.sd12 && (
                        <LegalStructurePdfTable>
                            <PdfTemplateLayout>
                                <div className={styles.pageHeader}>
                                    <h2>Tax features 2/2</h2>
                                </div>

                                <SD12Table />
                            </PdfTemplateLayout>
                        </LegalStructurePdfTable>
                    )}

                    {tableData && tableData.sd13 && (
                        <LegalStructurePdfTable>
                            <PdfTemplateLayout>
                                <div className={styles.pageHeader}>
                                    <h2>Asset manager</h2>
                                </div>

                                <SD13Table />
                            </PdfTemplateLayout>
                        </LegalStructurePdfTable>
                    )}

                    {tableData && tableData.sd14 && (
                        <LegalStructurePdfTable noFixed={true}>
                            <PdfTemplateLayout>
                                <div className={styles.pageHeader}>
                                    <h2>Required service providers</h2>
                                </div>

                                <SD14Table />
                            </PdfTemplateLayout>
                        </LegalStructurePdfTable>
                    )}
                </TablesWrapper>
            </ComparisonTableSectionProvider>
        </>
    );
};

export default LegalStructuresTemplate;
