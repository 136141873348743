import styles from './formats-styles.module.css';

const HoldersFormat = (d: any) => {
    let num: string | number = parseInt(d);
    if (!d) return '-';
    if (d && isNaN(num)) return d;

    num = new Intl.NumberFormat('en-US').format(num);

    return (
        <div className={styles.flex}>
            <img alt={''} src={'/icons/user.svg'} /> x {num}
        </div>
    );
};

export default HoldersFormat;
