import React, { useEffect, useState } from 'react';
import { Checkbox } from '../../../../components';
import { DealTaxesSelectedData } from '../../DealTaxes';
import { dealTaxesCheckboxes } from '../../../../utils/deal-taxes-checkboxes';
import SelectionLabel from '../../../../components/SelectionLabel/SelectionLabel';

import styles from './styles.module.css';
import { SelectionLevel } from '../../../../types/general';

interface AdditionalChecksProps {
    handleCheckbox: any;
    selectedData: DealTaxesSelectedData;
    isFoF?: boolean;
    fofLegalTypesDisabled?: boolean;
    fofOpenEndedDisabled?: boolean;
}

const CheckboxSingle = ({ selectedData, item, fofLegalTypesDisabled, fofOpenEndedDisabled, handleCheckbox }: any) => {
    const [disabled, setDisabled] = useState();
    const [label, setLabel] = useState(typeof item.label === 'string' ? item.label : '');
    const [isAvailable, setIsAvailable] = useState(item.isAvailable(selectedData || {}));

    useEffect(() => {
        setDisabled(
            item.isDisabled(selectedData, {
                fofLegalTypesDisabled,
                fofOpenEndedDisabled,
            })
        );
        setIsAvailable(item.isAvailable(selectedData));

        if (typeof item.label !== 'string') {
            setLabel(item.label(selectedData));
        }
    }, [selectedData, item]);

    return (
        <>
            {isAvailable && (
                <Checkbox
                    label={label}
                    value={true}
                    // @ts-ignore
                    checked={selectedData[item.type]}
                    onChange={handleCheckbox(item.type)}
                    disabled={disabled}
                    info={<>{typeof item.info === 'string' ? item.info : item.info(selectedData)}</>}
                />
            )}
        </>
    );
};

const ChecksGroup = ({
    isFoF,
    icon,
    items,
    selectedData,
    handleCheckbox,
    fofLegalTypesDisabled,
    fofOpenEndedDisabled,
}: any) => {
    return (
        <div className={styles.group}>
            {isFoF && icon}
            <div className={isFoF ? styles.checkboxes : ''}>
                {items.map((item: any, i: number) => (
                    <CheckboxSingle
                        key={i}
                        item={item}
                        selectedData={selectedData}
                        handleCheckbox={handleCheckbox}
                        fofLegalTypesDisabled={fofLegalTypesDisabled}
                        fofOpenEndedDisabled={fofOpenEndedDisabled}
                    />
                ))}
            </div>
        </div>
    );
};

const AdditionalChecks = ({
    handleCheckbox,
    selectedData,
    isFoF,
    fofLegalTypesDisabled,
    fofOpenEndedDisabled,
}: AdditionalChecksProps) => {
    const [groupedCheckboxes] = useState<any>(
        dealTaxesCheckboxes.reduce(function (obj, item) {
            const group = item.group;

            // @ts-ignore
            (obj[group] = obj[group] || []).push(item);
            return obj;
        }, {})
    );

    return (
        <>
            {/*{isFoF && <h5>Further selection:</h5>}*/}

            {!!groupedCheckboxes[SelectionLevel.Investor].filter((item: any) => item.isAvailable(selectedData))
                .length && (
                <ChecksGroup
                    icon={<SelectionLabel type={SelectionLevel.Investor} />}
                    isFoF={isFoF}
                    items={groupedCheckboxes[SelectionLevel.Investor]}
                    selectedData={selectedData}
                    handleCheckbox={handleCheckbox}
                    fofLegalTypesDisabled={fofLegalTypesDisabled}
                    fofOpenEndedDisabled={fofOpenEndedDisabled}
                />
            )}

            {isFoF && (
                <ChecksGroup
                    icon={<SelectionLabel type={SelectionLevel.FundOfFunds} />}
                    isFoF={isFoF}
                    items={groupedCheckboxes[SelectionLevel.FundOfFunds]}
                    selectedData={selectedData}
                    handleCheckbox={handleCheckbox}
                    fofLegalTypesDisabled={fofLegalTypesDisabled}
                    fofOpenEndedDisabled={fofOpenEndedDisabled}
                />
            )}

            <ChecksGroup
                icon={<SelectionLabel type={SelectionLevel.Fund} />}
                isFoF={isFoF}
                items={groupedCheckboxes[SelectionLevel.Fund]}
                selectedData={selectedData}
                handleCheckbox={handleCheckbox}
                fofLegalTypesDisabled={fofLegalTypesDisabled}
                fofOpenEndedDisabled={fofOpenEndedDisabled}
            />
        </>
    );
};

export default AdditionalChecks;
