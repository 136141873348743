const AddIcon = () => {
    return (
        <svg
            id="Component_63_18"
            data-name="Component 63 – 18"
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
        >
            <g id="Ellipse_11" data-name="Ellipse 11" fill="none" stroke="var(--icon-color)" strokeWidth="0.5">
                <circle cx="10.5" cy="10.5" r="10.5" stroke="none" />
                <circle cx="10.5" cy="10.5" r="10" fill="none" />
            </g>
            <line
                id="Line_7"
                data-name="Line 7"
                x1="6.513"
                y2="6.513"
                transform="translate(5.966 10.571) rotate(-45)"
                fill="none"
                stroke="var(--icon-color)"
                strokeWidth="0.5"
            />
            <line
                id="Line_8"
                data-name="Line 8"
                x2="6.513"
                y2="6.513"
                transform="translate(5.966 10.571) rotate(-45)"
                fill="none"
                stroke="var(--icon-color)"
                strokeWidth="0.5"
            />
        </svg>
    );
};

export default AddIcon;
