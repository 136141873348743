import styles from './styles.module.css';

interface DeleteButtonProps {
    onClick: () => void;
    size?: 'sm';
}

const DeleteButton = ({ onClick, size }: DeleteButtonProps) => {
    return <button className={`${styles.btn} ${size ? styles[size] : ''}`} type={'button'} onClick={onClick} />;
};

export default DeleteButton;
