import styles from './styles.module.css';
import DealTaxesDetailRow from './DealTaxesDetailRow';
import { Button, CloseButton, Icon } from '../../../../components';
import { DiagramDataTableShortItem } from '../../../../types/deal-taxes';
import React from 'react';
import { Icons } from '../../../../components/Icon/Icon';

interface DealTaxesDetailsProps {
    activeCategory: any;
    tips: DiagramDataTableShortItem[];
    onClose: () => void;
    legendChange: any;
    height?: number;
    isMultiAsset: boolean;
}

const DealTaxesDetails = ({
    activeCategory,
    tips,
    onClose,
    legendChange,
    height,
    isMultiAsset,
}: DealTaxesDetailsProps) => {
    const investment = tips.filter((t) => t.stage === 'investment');
    const holding = tips.filter((t) => t.stage === 'holding');
    const distribution = tips.filter((t) => t.stage === 'distribution');
    const selling = tips.filter((t) => t.stage === 'selling');

    return (
        <div
            className={styles.box}
            style={{
                height: height,
            }}
        >
            <div className={styles.content}>
                <div className={styles.closeBtnWrapper}>
                    <Button
                        type={'button'}
                        color={'neutral'}
                        onClick={() => onClose()}
                        after={<Icon icon={Icons.closeRounded} />}
                    >
                        Close
                    </Button>
                </div>

                {investment.length > 0 && (
                    <DealTaxesDetailRow
                        rowTitle={'Investment'}
                        data={investment}
                        isActive={activeCategory === 'investment' ?? true}
                        legendChange={legendChange}
                        isMultiAsset={isMultiAsset}
                    />
                )}

                {holding.length > 0 && (
                    <DealTaxesDetailRow
                        rowTitle={'Holding'}
                        data={holding}
                        isActive={activeCategory === 'holding' ?? true}
                        legendChange={legendChange}
                        isMultiAsset={isMultiAsset}
                    />
                )}

                {distribution.length > 0 && (
                    <DealTaxesDetailRow
                        rowTitle={'Distribution'}
                        data={distribution}
                        isActive={activeCategory === 'distribution' ?? true}
                        legendChange={legendChange}
                        isMultiAsset={isMultiAsset}
                    />
                )}

                {selling.length > 0 && (
                    <DealTaxesDetailRow
                        rowTitle={'Selling'}
                        data={selling}
                        isActive={activeCategory === 'selling' ?? true}
                        legendChange={legendChange}
                        isMultiAsset={isMultiAsset}
                    />
                )}
            </div>
        </div>
    );
};

export default DealTaxesDetails;
