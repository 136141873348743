import TableHeader from '../components/TableHeader';
import { Tr } from '../components';

import { BooleanFormat } from '../formats';

import { useTableStore } from '../../../store/useTableStore';

const SD13Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd13 ? (
        <table>
            <TableHeader sdKey={'sd13'} />

            <tbody>
                <Tr
                    skipFirstCol={true}
                    featName={'Global regulation type'}
                    dataKey={'sd13'}
                    colKey={'globalRegulationType'}
                />
                <Tr
                    skipFirstCol={true}
                    featName={'Self managed'}
                    dataKey={'sd13'}
                    colKey={'ifSelfManaged'}
                    formatFunc={BooleanFormat}
                />
                <Tr skipFirstCol={true} featName={'Asset manager'} dataKey={'sd13'} colKey={'amTitle'} />
            </tbody>
        </table>
    ) : null;
};

export default SD13Table;
