const AssetIcon = () => {
    return (
        <svg
            id="Component_81_13"
            data-name="Component 81 – 13"
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="37"
            viewBox="0 0 37 37"
        >
            <g
                id="Ellipse_23"
                data-name="Ellipse 23"
                fill="var(--icon-fill-color, none)"
                stroke="var(--icon-color)"
                strokeWidth="var(--icon-stroke-width, 0.5)"
            >
                <circle cx="18.5" cy="18.5" r="18.5" stroke="none" />
                <circle cx="18.5" cy="18.5" r="17.75" fill="var(--icon-fill-color)" />
            </g>

            <g id="Component_28_3" data-name="Component 28 – 3" transform="translate(9 10)">
                <g
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    transform="translate(0 8)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="var(--icon-stroke-width, 0.5)"
                >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                </g>
                <g
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    transform="translate(10 8)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="var(--icon-stroke-width, 0.5)"
                >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                </g>
                <g
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    transform="translate(5)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="var(--icon-stroke-width, 0.5)"
                >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                </g>
            </g>
        </svg>
    );
};

export default AssetIcon;
