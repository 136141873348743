import { DealTaxesSelectedData } from '../pages/DealTaxes/DealTaxes';
import { SelectionLevel } from '../types/general';

export enum DealTaxesCheckboxes {
    openEnded = 'openEnded',
    // corporate = 'corporate',
    ifCommercialPartnership = 'ifCommercialPartnership',
    ifBusinessAssets = 'ifBusinessAssets',
    booked = 'booked',

    ukInvesting = 'ukInvesting',
    fofUkInvesting = 'fofUkInvesting',

    fofOpenEnded = 'fofOpenEnded',
    // fofCorporate = 'fofCorporate',
    fofIfCommercialPartnership = 'fofIfCommercialPartnership',
}

export const CorporateLegalTypeIds = [1];

export const dealTaxesCheckboxes = [
    {
        type: 'booked',
        group: SelectionLevel.Investor,
        label: 'booked at NAV',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) =>
            selection.selectedInvestor?.countryCode?.toLowerCase() === 'ch',
        isDisabled: (selectedData: DealTaxesSelectedData) =>
            !!(selectedData.selectedInvestor && [5, 6, 15, 16].indexOf(+selectedData.selectedInvestor.id!) === -1),
        info: 'If the investment was booked at NAV or at cost.',
    },
    {
        type: 'openEnded',
        group: SelectionLevel.Fund,
        label: 'open-ended',
        defaultValue: () => false,
        isAvailable: () => true,
        isDisabled: (selectedData: DealTaxesSelectedData, data: any) => {
            return !!(
                selectedData.investmentFund.id && [1, 5, 6, 7, 8, 9].indexOf(+selectedData.investmentFund.id) > -1
            );
        },
        info: `Collective investment scheme whose investors have a legal right to redeem 
        or withdraw their shares or interest at least once per year.`,
    },
    {
        type: 'ifCommercialPartnership',
        group: SelectionLevel.Fund,
        label: 'commercial',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) =>
            selection.selectedInvestor?.countryCode?.toLowerCase() === 'de',
        isDisabled: (selectedData: DealTaxesSelectedData) =>
            CorporateLegalTypeIds.includes(selectedData.investmentFund.legalTypeId!),
        info: `A partnership is qualified as deemed commercial partnership, if only the 
        general partner is entitled to manage the partnership. On the other hand, 
        if one or more limited partners manage the partnership together with the general partner, 
        such a partnership is deemed to be non-commercial.`,
    },
    {
        type: 'ifBusinessAssets',
        group: SelectionLevel.Investor,
        label: 'business asset',
        defaultValue: (selectedData: DealTaxesSelectedData) => [14, 15].includes(selectedData?.selectedInvestor?.id!),
        isAvailable: (selection: DealTaxesSelectedData) => {
            return selection.selectedInvestor?.countryCode?.toLowerCase() === 'de';
        },
        isDisabled: (selectedData: DealTaxesSelectedData) => [14, 15].includes(selectedData?.selectedInvestor?.id!),
        info: 'If the investor books this allocation as business or non-business assets.',
    },
    {
        type: 'ukInvesting',
        group: SelectionLevel.Fund,
        label: (selection: DealTaxesSelectedData) =>
            selection.openEnded && CorporateLegalTypeIds.includes(selection.investmentFund?.legalTypeId!)
                ? 'reporting'
                : 'investing',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) => {
            return selection.selectedInvestor?.countryCode?.toLowerCase() === 'uk';
        },
        isDisabled: () => false,
        info: (selection: DealTaxesSelectedData) =>
            selection.openEnded && CorporateLegalTypeIds.includes(selection.investmentFund?.legalTypeId!)
                ? `A fund that has been granted reporting offshore
                fund status by HM Revenu
                e and Customs: it is an
                offshore fund that has applied to HMRC, been
                approved and maintains its status as a reporting
                fund.`
                : `The fund is classified as trading or investing
                according to HMRC's 'badges of trade' guidance:
                including numbe
                r of transactions, type of assets,
                source of funding, holding period etc. Careful
                analysis is required.`,
    },
    {
        type: 'fofOpenEnded',
        group: SelectionLevel.FundOfFunds,
        label: 'open-ended',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) => !!selection.fundOfFunds,
        isDisabled: (selectedData: DealTaxesSelectedData, { fofOpenEndedDisabled }: any) => fofOpenEndedDisabled,
        info: `Collective investment scheme whose investors have a legal right to redeem 
        or withdraw their shares or interest at least once per year.`,
    },
    {
        type: 'fofIfCommercialPartnership',
        group: SelectionLevel.FundOfFunds,
        label: 'commercial',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) =>
            !!selection.fundOfFunds && selection.selectedInvestor?.countryCode?.toLowerCase() === 'de',
        isDisabled: (selectedData: DealTaxesSelectedData) =>
            CorporateLegalTypeIds.includes(selectedData.fundOfFunds?.legalTypeId!),
        info: `A partnership is qualified as deemed commercial partnership, if only the general 
        partner is entitled to manage the partnership. On the other hand, if one or more 
        limited partners manage the partnership together with the general partner, 
        such a partnership is deemed to be non-commercial.`,
    },
    {
        type: 'fofUkInvesting',
        group: SelectionLevel.FundOfFunds,
        label: (selection: DealTaxesSelectedData) =>
            selection.fofOpenEnded && CorporateLegalTypeIds.includes(selection.fundOfFunds?.legalTypeId!)
                ? 'reporting'
                : 'investing',
        defaultValue: () => false,
        isAvailable: (selection: DealTaxesSelectedData) =>
            selection.selectedInvestor?.countryCode?.toLowerCase() === 'uk',
        isDisabled: () => false,
        info: (selection: DealTaxesSelectedData) =>
            selection.fofOpenEnded && CorporateLegalTypeIds.includes(selection.fundOfFunds?.legalTypeId!)
                ? `A fund that has been granted reporting offshore
                fund status by HM Revenu
                e and Customs: it is an
                offshore fund that has applied to HMRC, been
                approved and maintains its status as a reporting
                fund.`
                : `The fund is classified as trading or investing
                according to HMRC's 'badges of trade' guidance:
                including numbe
                r of transactions, type of assets,
                source of funding, holding period etc. Careful
                analysis is required.`,
    },
];

export const isDealTaxesCheckboxAvailable = (
    checkboxType: DealTaxesCheckboxes,
    selectedData: DealTaxesSelectedData
) => {
    return dealTaxesCheckboxes.find((check) => check.type === checkboxType)?.isAvailable(selectedData);
};
