import { ITipData } from '../components/Sankey/components/Tip';
import { SelectionLevel } from './general';

export interface DealTaxesExpandedTableItemData {
    d: string;
    f: string;
    h: string;
    j: string;
    k: string;
    l: string;
    m: string;
    n: string;
    o: string;
    p: string;
    color: string;
}

export interface DealTaxesExpandedTableItem {
    [key: string]: DealTaxesExpandedTableItemData;
}

// export enum ExtendedLevel{
//     ...SelectionLevel,
//     fof = 'fof',
// }

//
// export interface DiagramDataItem {
//     addUserParameter: string;
//     effectiveTaxRate: number;
//     eventCategory: EventCategory;
//     fullComments: string;
//     level: SelectionLevel;
//     paidBy: string;
//     tax: string;
//     taxBase: string;
//     taxRate: number;
//     exemption: number;
// }

export interface Value {
    value: number;
    exemptions?: string;
    amount?: string;
    highlight?: boolean;
}

export interface Path {
    source: number[];
    target: number[];
}

export enum EventCategory {
    distribution = 'distribution',
    holding = 'holding',
    selling = 'selling',
    investment = 'investment',
}

export enum FundLevel {
    fund = SelectionLevel.Fund,
    fof = SelectionLevel.FundOfFunds,
}
export interface SankeyTip {
    x: number;
    y: number;
    width: number;
    height: number;
    category: EventCategory;
    data: ITipData[];
}

export interface SankeyData {
    width: number;
    height: number;
    margin: any;
    nodes: SankeyNode[];
    tips: SankeyTip[];
    links: Link[];
    lines: any;
    bottomLines: any;
    xAxis: any;
    yAxis: any;
}

export interface Link {
    path: string | null;
    color?: string;
    width: number;
    opacity: number;
    class: string;
    source?: any;
    target?: any;
    type?: string;
    isFoF?: boolean;
    isDashed?: boolean;
}

export interface SankeyFundNode {
    x: number;
    y: number;
    width: number;
    height: number;
    level?: FundLevel;
    category?: EventCategory;
    text?: string;
    className?: string;
}

export interface SankeyNode {
    x: number;
    y: number;
    width: number;
    height: number;
    level: SelectionLevel;
    category: EventCategory;
    value: number;
    className?: string;
}

export interface DiagramDataItem {
    f5: number;
    't1/0': number;
    f6: number;
    a1: number;
    't3/0': number;
    f7: number;
    f10: number;
    a2: number;
    't5/0': number;
    f9: number;
    f8: number;
    't6/0': number;
    f13: number;
    't7/0': number;
    f12: number;
    a3: number;
    't9/0': number;
    f11: number;
    f16: number;
    f15: number;
    a4: number;
    't12/0': number;
    f14: number;
    netDistributions: number;
    netSold: number;
    totalTax: number;
    totalNetOroceeds: number;
    returnOnInvestment: number;
}

export interface DiagramDataTaxLabel {
    boxId: string;
    taxRate: number;
    tax: string;
    taxAmount: number;
    textComments: string;
    taxBase: string;
    level: string;
    exemption: any;
}

export interface DiagramDataTableShortItem {
    boxId: number;
    boxLevel: string;
    stage: string;
    tax: string;
    taxBase: string;
    taxRate: string;
    textComments: string;
    taxAmount: number;
    legendId?: number | string;
    assetClasses: string[];
}

export interface DiagramDataItems {
    nav: DiagramDataItem;
    taxLabel: DiagramDataTaxLabel[] | DiagramDataTaxLabel[][];
    popUpTable: DiagramDataTableShortItem[] | DiagramDataTableShortItem[][];
}

// export type DiagramData = DiagramDataItem[] | null;
export type DiagramData = DiagramDataItems;

export interface TipPositionConfig {
    sourceNode: SankeyNode | SankeyFundNode;
    targetNode: SankeyNode;
    tipData: any[];
    align: number;
    direction: number;
    offset?: boolean;
    overlapping?: any;
    shiftedX?: number;
    shiftedY?: number;
    fofNodes?: SankeyFundNode[];
}

export interface DiagramDataResponse {
    result: DiagramData;
    corporate: boolean;
}
