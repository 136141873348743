import styles from './styles.module.css';
import { Countries, GraphTypes } from '../../../types/general';
import DeleteButton from '../../DeleteButton';
import SelectionLayoutRow from '../SelectionLayoutRow/SelectionLayoutRow';
import { useEffect, useState } from 'react';
import { useDiagramStore } from '../../../store/useDiagramStore';

interface SelectionRowCompactProps {
    country: string;
    value: string;
    assetAllocation: number;
    handleDelete: any;
    validateAssetAllocationChange: any;
    onClick: any;
    isAllocationChangeable?: boolean;
}

const SelectionRowCompact = ({
    country,
    value,
    assetAllocation,
    handleDelete,
    validateAssetAllocationChange,
    onClick,
    isAllocationChangeable,
}: SelectionRowCompactProps) => {
    const [graphType] = useDiagramStore((state) => [state.graphType]);

    const [input, setInput] = useState(assetAllocation);

    useEffect(() => {
        setInput(assetAllocation);
    }, [assetAllocation]);

    return (
        <SelectionLayoutRow
            isSecondary={true}
            onClick={onClick}
            afterRow={<DeleteButton onClick={handleDelete} size={'sm'} />}
        >
            <div>
                <div className={`${styles.column}`}>
                    {/*// @ts-ignore*/}
                    {Countries[country?.toLowerCase()]?.domicileName}

                    {/*// @ts-ignore*/}
                    <img width={30} src={Countries[country?.toLowerCase()]?.img} alt="" />
                </div>
            </div>
            <div>
                <div className={`${styles.column}`}>{value}</div>
            </div>
            <div className={styles.flex}>
                <div className={`${styles.column} ${styles.sm}`}>
                    {isAllocationChangeable ? (
                        <input
                            type="number"
                            step={2}
                            value={input}
                            onInput={(e: any) => {
                                console.log('value', e.target.value, e.target.value.replace(/[^\d]+/g, ''));
                                setInput(e.target.value.replace(/[^\d]+/g, ''));
                            }}
                            onBlur={() => validateAssetAllocationChange(+input)}
                        />
                    ) : (
                        assetAllocation
                    )}
                    {graphType !== GraphTypes.Legal && '%'}
                </div>
                <span className={styles.note}>of AuM</span>
            </div>
        </SelectionLayoutRow>
    );
};

export default SelectionRowCompact;
