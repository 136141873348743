import create from 'zustand';
import axios from '../utils/axios';
import {
    Asset,
    Countries,
    Country,
    GraphTypes,
    InvestmentFund,
    Investor,
    LegalForm,
    TCountryCode,
} from '../types/general';

type AssetType = 'deal-taxes' | 'legal-structures';

type DiagramStoreBase = {
    graphType: undefined | GraphTypes;
    useInvestmentStructure: boolean;
};

export type ItemsByCountry<T> = { [key in TCountryCode]?: T[] };
export type ItemsById<T> = { number?: T[] };

interface DiagramStore extends DiagramStoreBase {
    setStoreData: (data: DiagramStoreBase) => void;
    setGraphType: (type: GraphTypes) => void;
    investors: ItemsByCountry<Investor>;
    investmentFunds: ItemsByCountry<InvestmentFund>;
    fundOfFunds: ItemsByCountry<InvestmentFund>;
    assets: ItemsByCountry<Asset>;
    legalForms: ItemsById<LegalForm>;
    setInvestors: (country: string) => void;
    setInvestmentFunds: (country: string) => void;
    setFundOfFunds: (country: string) => void;
    setAssets: (country: string, type?: AssetType) => Promise<ItemsByCountry<Asset>>;
    setLegalForms: (fundId: number) => void;
    resetData: () => void;

    countries: Country[];
    fundCountries: Country[];
    fofCountries: Country[];
    getCountries: () => Promise<Country[]>;
}

export const useDiagramStore = create<DiagramStore>((set, get) => ({
    useInvestmentStructure: false,
    setStoreData: (data: DiagramStoreBase) => {
        if (data.graphType !== get().graphType) {
            get().resetData();
        }

        set({
            ...data,
        });
    },
    graphType: undefined,
    setGraphType: (type: GraphTypes) => {
        set({
            graphType: type,
        });
    },
    investors: {},
    investmentFunds: {},
    assets: {},
    fundOfFunds: {},
    countries: [],
    fundCountries: [],
    fofCountries: [],
    legalForms: {},
    resetData: () => {
        // set({
        //     // investors: {},
        //     // investmentFunds: {},
        //     // fundOfFunds: {},
        //     // assets: {},
        // });
    },
    setInvestors: async (country: string) => {
        const { data } = await axios.get(`/base/investors/${country}`);

        set({
            investors: { ...get().investors, [country]: data },
        });
    },
    setAssets: async (country: string, type?: AssetType) => {
        let assets;
        if (type === 'deal-taxes') {
            const { data } = await axios.get(`/deal-taxes/assets/${country}`);
            assets = data;
        } else {
            const { data } = await axios.get(`/legal-structures/assets/${country}`);
            assets = data;
        }

        set({
            assets: { ...get().assets, [country]: assets },
        });

        return assets;
    },
    setInvestmentFunds: async (country: string) => {
        const { data } = await axios.get(`/base/investment-funds/${country}`);
        set({
            investmentFunds: { ...get().investmentFunds, [country]: data },
        });
    },
    setFundOfFunds: async (country: string) => {
        const { data } = await axios.get(`/base/investment-funds/${country}`);
        set({
            fundOfFunds: { ...get().fundOfFunds, [country]: data },
        });
    },
    setLegalForms: async (fundId: number) => {
        const { data } = await axios.get(`/base/legal-forms/${fundId}`);
        set({
            legalForms: { ...get().legalForms, [fundId]: data },
        });
    },
    // setInvestorsByData: (data: any) => {
    //     set({
    //         investors: data,
    //     });
    // },
    // setAssetsByData: (data: any) => {
    //     set({
    //         assets: data,
    //     });
    // },
    // setInvestmentFundsByData: (data: any) => {
    //     set({
    //         investmentFunds: data,
    //     });
    // },
    getCountries: async (): Promise<Country[]> => {
        const fundCountries = [Countries.ky.domicile, Countries.lu.domicile];
        const fofCountries = [Countries.ky.domicile, Countries.lu.domicile];

        // if (get().graphType === GraphTypes.Taxes) {
        const { data } = await axios.get(`/deal-taxes/countries`);
        set({
            countries: data.filter((country: Country) => fundCountries.indexOf(country.domicile.toLowerCase()) === -1),
            fundCountries: data.filter(
                (country: Country) => fundCountries.indexOf(country.domicile.toLowerCase()) > -1
            ),
            fofCountries: data.filter((country: Country) => fofCountries.indexOf(country.domicile.toLowerCase()) > -1),
        });

        return data;
        // } else {
        //     const countries = [Countries.ch, Countries.de, Countries.uk];
        //
        //     set({
        //         countries: countries,
        //     });
        //
        //     return countries;
        // }
    },
}));
