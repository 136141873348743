import TableHeader from '../components/TableHeader';
import { Tr } from '../components';
import { BooleanFormat, DescriptionFormat, PeopleFormat } from '../formats';
import { HoldersFormat } from '../formats';
import TrProvider from '../components/TrProvider';
import { useTableStore } from '../../../store/useTableStore';

const SD2Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd2 ? (
        <table>
            <TableHeader sdKey={'sd2'} />

            <tbody>
                <Tr featName={'Legal form'} dataKey={'sd2'} colKey={'legalForm'} />

                <TrProvider>
                    <Tr
                        featName={'Umbrella / segregated sub-funds'}
                        dataKey={'sd2'}
                        colKey={'umbrella'}
                        formatFunc={BooleanFormat}
                        descriptionCol={'umbrellaComments'}
                    />
                </TrProvider>

                <Tr
                    featName={'Cross sub-funds investment'}
                    dataKey={'sd2'}
                    colKey={'umbrellaCrossInvestments'}
                    formatFunc={BooleanFormat}
                />

                <Tr
                    featName={'Classes of shares'}
                    dataKey={'sd2'}
                    colKey={'classesOfShares'}
                    formatFunc={BooleanFormat}
                />

                <Tr featName={'Master - feader'} dataKey={'sd2'} colKey={'feeder'} formatFunc={BooleanFormat} />

                <TrProvider>
                    <Tr
                        featName={'Listing'}
                        dataKey={'sd2'}
                        colKey={'listing'}
                        formatFunc={BooleanFormat}
                        descriptionCol={'listingText'}
                    />
                </TrProvider>

                <Tr featName={'Side pockets'} dataKey={'sd2'} colKey={'sidePockets'} formatFunc={BooleanFormat} />

                <Tr featName={'Liquidity'} dataKey={'sd2'} colKey={'liquidityDetails'} />

                <Tr featName={'Evergreen'} dataKey={'sd2'} colKey={'evergreen'} formatFunc={BooleanFormat} />

                <Tr
                    featName={'Min number of shareholders'}
                    dataKey={'sd2'}
                    colKey={'minNumOfShareholders'}
                    formatFunc={PeopleFormat}
                />

                <TrProvider>
                    <Tr
                        featName={'Max number of shareholders'}
                        dataKey={'sd2'}
                        colKey={'maxNumOfShareholders'}
                        formatFunc={HoldersFormat}
                        descriptionCol={'maxNumOfShareholdersComments'}
                    />
                </TrProvider>

                <TrProvider>
                    <Tr
                        featName={'Risk diversification requirements'}
                        dataKey={'sd2'}
                        colKey={'riskDiversificationText'}
                        formatFunc={DescriptionFormat}
                    />
                </TrProvider>
            </tbody>
        </table>
    ) : null;
};

export default SD2Table;
