const CurrencyFormat = (d: any) => {
    if (!d) return '-';

    const arr = d.toString().split(' ');

    if (!isNaN(parseInt(arr[0])) && arr.length === 2) {
        d = new Intl.NumberFormat('en-US').format(arr[0]).replace(/,/g, "'") + ' ' + arr[1];
    }

    return d;
};
export default CurrencyFormat;
