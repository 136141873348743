import { useEffect, useState } from 'react';

import Triangle from './components/Triangle';
import Tip from './components/Tip';
import { XAxis, YAxis } from './components/Axis';
import Line from './components/Line';
import Node from './components/Node';
import FundNode from './components/FundNode';
import React from 'react';

interface SankeyProps {
    sankey: any;
    onDataChange?: any;
    activeCategory?: string | null;
    onLegendChange?: any;
    graphRef?: any;
}

const Sankey = ({ sankey, onDataChange, activeCategory, onLegendChange, graphRef }: SankeyProps) => {
    //const [activeCategory, setActiveCategory] = useState(null);
    const [prevActive, setPrevActive] = useState<string | undefined | null>(null);

    useEffect(() => {
        if (!activeCategory && prevActive !== activeCategory) {
            setDefaultOpacity('investment');
            setDefaultOpacity('holding');
            setDefaultOpacity('selling');
            setDefaultOpacity('distribution');
        }

        setPrevActive(activeCategory);
    }, [activeCategory]);

    useEffect(() => {
        setDefaultOpacity('investment');
        setDefaultOpacity('holding');
        setDefaultOpacity('selling');
        setDefaultOpacity('distribution');

        if (activeCategory) {
            setActiveOpacity(activeCategory);
        }
    }, [sankey]);

    const setDefaultOpacity = (e: any) => {
        const elements: any = document.querySelectorAll(`.${e}`);

        for (const element of elements) {
            element.style.opacity = null;
        }
    };

    const onLinkEnter = (e: any) => {
        setActiveOpacity(e);
    };

    const setActiveOpacity = (e: any) => {
        const elements: any = document.querySelectorAll(`.${e}`);

        for (const element of elements) {
            if (element.classList.contains('tip-link')) {
                element.style.opacity = 0.5;
            } else if (element.classList.contains('tip-arrow') && !element.classList.contains('link')) {
                element.style.opacity = 1;
            } else {
                element.style.opacity = 0.8;
            }
        }
    };

    const onClick = (e: any) => {
        if (e.target.tagName === 'path') {
            const category = e.target.className.baseVal;

            if (activeCategory === category) {
                setDefaultOpacity(activeCategory);
                onDataChange(null);

                return;
            }

            onLinkEnter(category);

            if (activeCategory) {
                setDefaultOpacity(activeCategory);
            }

            onDataChange(category);
        } else {
            setDefaultOpacity(activeCategory);
            onDataChange(null);
        }
    };

    const TipLinks = ({ isFoF }: { isFoF: boolean }) =>
        sankey.links.map(
            (l: any, i: any) =>
                ((isFoF && l.isFoF) || (!isFoF && !l.isFoF)) && (
                    <React.Fragment key={i}>
                        {l.type && l.type === 'triangle' ? (
                            <Triangle link={l} i={i} />
                        ) : (
                            <path
                                className={l.class}
                                d={l.path}
                                stroke={l.color}
                                fill="none"
                                opacity={l.opacity}
                                strokeWidth={l.width}
                                strokeDasharray={l.isDashed ? '2,2' : 0}
                            />
                        )}
                    </React.Fragment>
                )
        );

    return (
        <>
            <svg
                width={sankey.width}
                height={sankey.height}
                onClick={onClick}
                viewBox={`0 0 ${sankey.width} ${sankey.height}`}
                ref={graphRef}
            >
                <rect width={sankey.width} height={sankey.height} fill={'none'} />

                {sankey.xAxis.map((a: any, i: number) => (
                    <XAxis key={i} axis={a} />
                ))}

                <TipLinks isFoF={false} />

                {sankey.fundNodes.map((d: any, i: number) => (
                    <FundNode key={i} node={d} />
                ))}

                {sankey.yAxis.map((a: any, i: number) => (
                    <YAxis key={i} axis={a} />
                ))}

                <TipLinks isFoF={true} />

                {sankey.nodes.map((d: any, i: number) => (
                    <Node key={i} node={d} />
                ))}

                {sankey.lines.map((l: any, i: number) => (
                    <Line key={i} line={l} strokeWidth={1} />
                ))}

                {sankey.bottomLines.map((l: any, i: number) => (
                    <Line key={i} line={l} strokeWidth={2} />
                ))}

                {sankey.tips.map((t: any, i: number) => (
                    <Tip key={i} tip={t} onLegendChange={onLegendChange} />
                ))}
            </svg>
        </>
    );
};

export default Sankey;
