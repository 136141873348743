import { default as ReactSelect } from 'react-select';
import styles from './Select.module.css';
import { useEffect, useState } from 'react';

const CustomChevron = ({ innerRef, ...innerProps }: any) => {
    return (
        <svg
            ref={innerRef}
            {...innerProps}
            xmlns="http://www.w3.org/2000/svg"
            width="19.931"
            height="10.673"
            viewBox="0 0 19.931 10.673"
        >
            <path
                id="Path_1174"
                data-name="Path 1174"
                d="M5271.342,2230l9.612,9.612,9.612-9.612"
                transform="translate(-5270.988 -2229.646)"
                fill="none"
                stroke="currentcolor"
                strokeWidth="1"
            />
        </svg>
    );
};

const CustomMenu = ({ placement, selectProps, innerRef, innerProps, isDisabled, children, ...props }: any) => {
    return !isDisabled ? (
        <div
            ref={innerRef}
            {...innerProps}
            style={{
                position: 'absolute',
                zIndex: 10,
                background: '#fff',
                width: '100%',
                border: '0.5px solid #7B848E',
                borderRadius: '2px',
                bottom: placement === 'top' ? '100%' : 'initial',
            }}
        >
            {children}

            {!!selectProps.onCreateOption && (
                <button
                    type={'button'}
                    className="btn btn-info btn-sm btn-block"
                    onClick={() => {
                        selectProps.onCreateOption();
                        selectProps.onMenuClose();
                    }}
                    style={{
                        width: '100%',
                        color: '#1650B4',
                        borderTop: '1px solid #D6D6D6',
                        borderRadius: 0,
                        padding: '6px 22px',
                        textAlign: 'left',
                        fontSize: '14px',
                    }}
                >
                    Add another
                </button>
            )}
        </div>
    ) : null;
};

const CustomSelect = ({
    children,
    options = [],
    value,
    valueKey = 'value',
    labelKey = 'label',
    isMulti = false,
    onCreateOption,
    onChange,
    disabled,
    info,
    placeholder,
    ...props
}: any) => {
    const [customValue, setCustomValue] = useState<any>(undefined);

    const getValue = () => {
        const option = !value
            ? ''
            : Array.isArray(value)
            ? value
            : typeof value !== 'object'
            ? options.find((option: any) => (option[valueKey] || option.value) === value)
            : value;

        console.log({
            value,
            valueKey,
            options,
            'typeof value': typeof value,
            isArray: Array.isArray(value),
            option,
            selected: Array.isArray(value)
                ? value
                : option
                ? {
                      label: option[labelKey] || option.label || option.name,
                      value: option[valueKey] || option.value || option.id,
                  }
                : '',
        });

        setCustomValue(
            Array.isArray(value)
                ? value
                : option
                ? {
                      label: option[labelKey] || option.label || option.name,
                      value: option[valueKey] || option.value || option.id,
                  }
                : ''
        );
    };

    useEffect(() => {
        getValue();
    }, [value, options]);

    return (
        <label className={`${styles.input} ${!!children ? styles.offset : ''} ${!!info ? styles.extended : ''}`}>
            {!!children && <span className={styles.label}>{children}</span>}

            {/* ts-ignore */}
            <ReactSelect
                onCreateOption={onCreateOption}
                onChange={onChange}
                isMulti={isMulti}
                isDisabled={disabled}
                // defaultValue={customValue}
                value={customValue}
                options={options.map((option: any) => ({
                    label: option[labelKey] || option.label,
                    value: option[valueKey] || option.value,
                }))}
                components={{ Menu: CustomMenu, DropdownIndicator: CustomChevron }}
                unstyled={true}
                placeholder={placeholder || 'Select...'}
                menuPlacement="auto"
                minMenuHeight={160}
                maxMenuHeight={200}
                // menuPortalTarget={document.body}
                // menuPosition={'fixed'}
                styles={{
                    menuPortal: (provided: any) => ({
                        ...provided,
                        zIndex: 999,
                    }),
                }}
                classNames={{
                    control: (state) => `${styles.select} ${state.isDisabled ? styles.disabled : ''}`,
                    option: (state) => styles.option,
                    clearIndicator: (state) => styles.clearIndicator,
                    noOptionsMessage: (state) => styles.noOptionsMessage,
                    multiValue: (state) => styles.multiValue,
                }}
                // closeMenuOnScroll={(e) => {
                //     // if (e.target === document) {
                //     return true;
                //     // } else {
                //     //     return false;
                //     // }
                // }}
            />

            {!!info && <p>{info}</p>}
        </label>
    );
};

export default CustomSelect;
