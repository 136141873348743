import { SelectionCombination } from '../../../components/SelectedCombination';
import styles from './styles.module.css';
import { Button, Checkbox, Icon } from '../../../components';
import { AdditionalChecks } from './AdditionalChecks';
import SelectionMenuBase from '../../../components/SelectionMenuBase/SelectionMenuBase';
import React, { useEffect, useState } from 'react';
import { DealTaxesInfo } from './DealTaxesInfo';
import { SelectedItem } from '../../../components/SelectionPopup/SelectionPopup';
import { Icons } from '../../../components/Icon/Icon';

const groups = {
    investors: 'investors',
    selection: 'selection',
    checkboxes: 'checkboxes',
};

const MenuGroup = ({ icon, label, openCategory, category, setOpenCategory, children, contentHeight }: any) => {
    const [isOpen, setIsOpen] = useState(openCategory === category);
    const [isInternallyOpen, setIsInternallyOpen] = useState(true);

    useEffect(() => {
        if (window.innerHeight > contentHeight) {
            setIsOpen(isInternallyOpen);
        } else {
            setIsOpen(openCategory === category);
        }
    }, [contentHeight, isInternallyOpen, openCategory]);

    const toggleOpen = () => {
        setOpenCategory(category);
        setIsInternallyOpen(!isInternallyOpen);
    };

    return (
        <div className={`${styles.group} ${isOpen ? styles.open : ''}`}>
            <div className={styles.groupHeader} onClick={toggleOpen}>
                <div className={styles.groupIcon}>
                    <Icon icon={icon} />
                </div>

                <h6>{label}</h6>
            </div>
            {isOpen && <div className={styles.groupContent}>{children}</div>}
        </div>
    );
};

const DealTaxesAsideMenu = ({
    isOptimisation,
    selectedData,
    setSelectedInvestor,
    onEdit,
    active,
    setActive,
    hidden,
    infoProps,
    checksProps,
}: any) => {
    const [openCategory, setOpenCategory] = useState<undefined | string>(groups.selection);
    const [contentHeight, setcontentHeight] = useState(900);

    useEffect(() => {
        setcontentHeight(970 + 80 * selectedData.investors.length + 20 * selectedData.assets.length);
    }, [selectedData]);

    const toggleCategory = (category: any) => {
        setOpenCategory(openCategory === category ? undefined : category);
    };

    const handleSelectedInvestor = (id: number) => () => {
        setSelectedInvestor(selectedData.investors.find((investor: SelectedItem) => investor.id === id));
    };

    return (
        <SelectionMenuBase
            info={<DealTaxesInfo {...infoProps} />}
            active={active}
            setActive={setActive}
            hidden={hidden}
        >
            <div className={styles.mainInfo}>
                <MenuGroup
                    icon={Icons.asset}
                    label={'Initial inputs: investors, INVESTMENT STRUCTURE, ASSETS'}
                    setOpenCategory={toggleCategory}
                    openCategory={openCategory}
                    category={groups.selection}
                    contentHeight={contentHeight}
                >
                    <SelectionCombination
                        selectedData={selectedData}
                        isOptimisation={false}
                        hideFundRow={false}
                        isSelectable={true}
                        handleSelectedInvestor={handleSelectedInvestor}
                    />

                    <div className={styles.btnContainer}>
                        <Button type={'button'} color={'neutral'} onClick={onEdit}>
                            Edit
                        </Button>
                    </div>
                </MenuGroup>

                <MenuGroup
                    icon={Icons.checksGroup}
                    label={'Further selection'}
                    openCategory={openCategory}
                    setOpenCategory={toggleCategory}
                    category={groups.checkboxes}
                    contentHeight={contentHeight}
                >
                    <AdditionalChecks {...checksProps} />
                </MenuGroup>
            </div>
        </SelectionMenuBase>
    );
};

export default DealTaxesAsideMenu;
