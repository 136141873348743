const CloseIcon = () => {
    return (
        <svg
            id="small_20_"
            data-name="small (20)"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
        >
            <g id="Ellipse_4" data-name="Ellipse 4" fill="none" stroke="var(--icon-color)" strokeWidth="0.5">
                <circle cx="10" cy="10" r="10" stroke="none" />
                <circle cx="10" cy="10" r="9.5" fill="none" />
            </g>
            <g id="Group_6054" data-name="Group 6054" transform="translate(-603.5 -3842.5)">
                <line
                    id="Line_7"
                    data-name="Line 7"
                    x1="10"
                    y2="10"
                    transform="translate(608.5 3847.5)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                />
                <line
                    id="Line_8"
                    data-name="Line 8"
                    x2="10"
                    y2="10"
                    transform="translate(608.5 3847.5)"
                    fill="none"
                    stroke="var(--icon-color)"
                    strokeWidth="0.5"
                />
            </g>
        </svg>
    );
};

export default CloseIcon;
