import SelectionMenuBase from '../../../components/SelectionMenuBase/SelectionMenuBase';
import { Info } from '../../LegalStructures/components/Info';
import { SelectionCombination } from '../../../components/SelectedCombination';
import styles from '../../LegalStructures/components/styles.module.css';
import { Button } from '../../../components';
import React, { useEffect } from 'react';
import BaseInputs from './BaseInputs/BaseInputs';

const TaxOptimisationSelection = ({
    setSelectedData,
    selectedData,
    selectedDetails,
    onEdit,
    active,
    setActive,
    hidden,
    handleSelectedInvestor,
}: any) => {
    // useEffect(() => {
    //     console.log('selectedDetails', selectedDetails, { ...(selectedDetails || {}), ...selectedData });
    // }, [selectedDetails]);
    return (
        <>
            <SelectionMenuBase
                info={<BaseInputs selectedData={selectedData} setSelectedData={setSelectedData} />}
                active={active}
                setActive={setActive}
                hidden={hidden}
            >
                <div
                    style={{
                        marginTop: 160,
                    }}
                >
                    <SelectionCombination
                        selectedData={{ ...(selectedDetails || {}), ...selectedData }}
                        isOptimisation={!!selectedDetails}
                        hideFundRow={true}
                        fundData={
                            !selectedDetails?.investmentFund
                                ? undefined
                                : {
                                      fund: selectedDetails?.investmentFund.name,
                                      country: selectedDetails?.investmentFund.countryCode,
                                  }
                        }
                        isSelectable={true}
                        handleSelectedInvestor={handleSelectedInvestor}
                    />
                    <div className={styles.btnContainer}>
                        <Button type={'button'} outlined={true} onClick={onEdit}>
                            Edit
                        </Button>
                    </div>
                </div>
            </SelectionMenuBase>

            {/*<div>*/}
            {/*    <TransitionComponent inProp={!active} duration={100}>*/}
            {/*        <p className={styles.graphTip}>*/}
            {/*            /!*{comments ? `${comments.legendId} : ${comments.fullComments}` : ''}*!/*/}
            {/*            {legends*/}
            {/*                ? legends.map((l, i) => (*/}
            {/*                      <React.Fragment key={i}>*/}
            {/*                          {l} <br />*/}
            {/*                      </React.Fragment>*/}
            {/*                  ))*/}
            {/*                : ''}*/}
            {/*        </p>*/}
            {/*    </TransitionComponent>*/}
            {/*</div>*/}
        </>
    );
};

export default TaxOptimisationSelection;
