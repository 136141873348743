import React, { useEffect, useState } from 'react';
import { ItemsByCountry, useDiagramStore } from '../../../store/useDiagramStore';
import {
    Asset,
    Country,
    InvestmentFund,
    Investor,
    SelectionLevel,
    SelectionLevelProps,
    TCountryCode,
} from '../../../types/general';
import { SelectionRowContext } from '../../../context';
import SelectionCheckboxRow from '../SelectionCheckboxRow/SelectionCheckboxRow';
import { Button, Icon } from '../../index';
import { SelectedItemAsset, SelectedItemExtended } from '../SelectionPopup';
import DeleteButton from '../../DeleteButton';
import SelectionRowCompact from '../SelectionRowCompact/SelectionRowCompact';
import SelectionRow from '../SelectionRow/SelectionRow';
import SelectionLayoutLevelContainer from '../SelectionLayoutRow/SelectionLayoutLevelContainer';
import SelectionLayoutRow from '../SelectionLayoutRow/SelectionLayoutRow';
import { Icons } from '../../Icon/Icon';
import { formatAllocation, getValidAllocation } from '../../../utils/asset-allocation';
import styles from './styles.module.css';

interface GroupedSelectionItem {
    id: number | undefined;
    name: string | undefined;
    assetAllocation: number;
}

interface GroupedSelectionItemAsset extends GroupedSelectionItem {
    currentAssetTaxId: number | undefined;
}

interface GroupedSelectionBase {
    countryCode: TCountryCode | undefined;
    countryName: string | undefined;
    countryId: string | number | undefined;
}

export interface GroupedSelectionSingle extends GroupedSelectionBase {
    item: GroupedSelectionItemAsset;
}

export interface GroupedSelectionMulti extends GroupedSelectionBase {
    items: GroupedSelectionItem[];
    item?: GroupedSelectionItemAsset;
}

// todo types
const SelectionPopupRowMulti = ({ initData, isMulti = false, type, onSelect, options, focusedType }: any) => {
    const setDataFromContext = (allowEmpty = false, skipAllocationCheck = false) => {
        const base = {
            countryCode: undefined,
            countryName: undefined,
            countryId: undefined,
            // assetAllocation: getValidAllocation(undefined, undefined, 0, 100),
        };

        // return { ...base, items: [] } as GroupedSelectionMulti;
        return allowEmpty
            ? undefined
            : isMulti
            ? ({ ...base, items: [] } as GroupedSelectionMulti)
            : ({
                  ...base,
                  item: {
                      id: undefined,
                      name: undefined,
                      currentAssetTaxId: undefined,
                      assetAllocation: getValidAllocation({
                          currentValue: 100,
                          value: 100,
                          skipCheck: skipAllocationCheck,
                      }),
                  },
              } as GroupedSelectionSingle);
    };

    // unfolded selecting row
    const [groupedData, setGroupedData] = useState<undefined | GroupedSelectionMulti | GroupedSelectionSingle>(
        setDataFromContext(!!initData?.length, true)
    );

    // folder selection split by country, each row
    const [ungroupedData, setUngroupedData] = useState<SelectedItemExtended[] | SelectedItemAsset[]>([]);

    const [investors, assets, countries, getCountries, setAssets, setInvestors] = useDiagramStore((state) => [
        state.investors,
        state.assets,
        state.countries,
        state.getCountries,
        state.setAssets,
        state.setInvestors,
    ]);

    const [availableCountries, setAvailableCountries] = useState<Country[]>(countries);
    const [availableOptions, setAvailableOptions] = useState<
        ItemsByCountry<Investor> | ItemsByCountry<Asset> | ItemsByCountry<InvestmentFund>
    >(options);

    const [isGroupFilled, setIsGroupFilled] = useState(false);

    useEffect(() => {
        initData && setUngroupedData(initData);
    }, []);

    useEffect(() => {
        if (type === SelectionLevel.Investor && focusedType !== type && ungroupedData.length > 0) {
            if (toggleGroupedData()) {
                setGroupedData(undefined);
            }
        } else if (type === SelectionLevel.Asset && focusedType !== type) {
            if (toggleGroupedData()) {
                setGroupedData(undefined);
            }
        }
    }, [focusedType]);

    useEffect(() => {
        const groupedItems = groupedData
            ? 'items' in groupedData
                ? groupedData.items.length
                    ? groupedData.items
                    : [{ id: undefined, name: undefined }]
                : [groupedData.item]
            : [];

        setIsGroupFilled(!!(!groupedData || (groupedItems[0]?.id && groupedData && groupedData.countryCode)));
    }, [groupedData]);

    useEffect(() => {
        if (groupedData && (groupedData.countryCode || !ungroupedData.length)) {
            const groupedItems =
                'items' in groupedData
                    ? groupedData.items.length
                        ? groupedData.items
                        : [{ id: undefined, name: undefined, assetAllocation: 0 }]
                    : [groupedData.item];

            // if ((!groupedItems.length && !ungroupedData.length) || (groupedItems && !groupedItems[0].id)) return;

            onSelect([
                ...ungroupedData,
                ...groupedItems.map((item: GroupedSelectionItem | GroupedSelectionItemAsset) => {
                    const tmp: SelectedItemExtended | SelectedItemAsset = {
                        id: item.id,
                        name: item.name,
                        assetAllocation: item.assetAllocation,
                        countryCode: groupedData.countryCode,
                        countryName: groupedData.countryName,
                        countryId: groupedData.countryId,
                    };

                    if ('currentAssetTaxId' in item) {
                        (tmp as SelectedItemAsset).currentAssetTaxId = item.currentAssetTaxId;
                    }

                    return tmp;
                }),
            ]);
        } else {
            onSelect([...ungroupedData]);
        }
    }, [ungroupedData, groupedData]);

    useEffect(() => {
        let tmp: any = {};

        for (const key of Object.keys(options)) {
            tmp[key] = options[key].filter(
                (option: Investor | Asset) =>
                    !ungroupedData.find(
                        (item) =>
                            // TODO types
                            // @ts-ignore
                            item.id === option[SelectionLevelProps[type].valueKey] &&
                            (('domicile' in option &&
                                item.countryCode?.toLowerCase() === option.domicile?.toLowerCase()) ||
                                !('domicile' in option))
                    )
            );
        }

        setAvailableOptions(tmp);
        setAvailableCountries(countries.filter((country) => !tmp[country.domicile] || !!tmp[country.domicile].length));

        // ungroupedData.find
    }, [ungroupedData, options]);

    const handleSelect = (prop: any, index?: number) => (event: any) => {
        // @ts-ignore
        const value = event?.target?.value || event?.value || event.map((item) => item.value);
        let totalSelected = ungroupedData.length;

        // checkbox clicked, multi select
        if ('items' in groupedData!) {
            const isSelect = !event.target;

            let tmp = [...groupedData.items];

            if (typeof index !== 'undefined' && isSelect) {
                tmp[index] = {
                    id: +value,
                    name: investors[groupedData.countryCode!]!.find((inv) => inv.investorId === +value)!.investor,
                    assetAllocation: 0,
                };
            } else {
                const index = tmp.findIndex((item) => item.id === +value);
                if (index === -1) {
                    tmp.push({
                        id: +value,
                        name: investors[groupedData.countryCode!]!.find((inv) => inv.investorId === +value)!.investor,
                        assetAllocation: 0,
                    });
                } else {
                    tmp.splice(index, 1);
                }
            }

            totalSelected += tmp.length;

            tmp = tmp.map((item) => {
                item.assetAllocation = formatAllocation(100 / totalSelected);

                return item;
            });

            setGroupedData({ ...groupedData, items: tmp });

            console.log('{ ...groupedData, items: tmp }', { ...groupedData, items: tmp });
        } else {
            totalSelected += 1;

            const currentAsset = assets[groupedData!.countryCode!]!.find((a) => +a.assetId === +value)!;

            let tmp = {
                id: +value,
                currentAssetTaxId: currentAsset.jTblAssetDomicileId,
                name: currentAsset.assetClass,
                assetAllocation: formatAllocation(100 / totalSelected),
            };

            setGroupedData({ ...groupedData!, item: tmp });
        }

        setUngroupedData(
            ungroupedData.map((item) => {
                item.assetAllocation = formatAllocation(100 / totalSelected);
                return item;
            })
        );

        if (type === SelectionLevel.Investor) {
            const selectionPopup = document.getElementById('selectionPopupBox')!;
            selectionPopup.scrollTop = selectionPopup.scrollHeight;
        }
    };

    const handleCountrySelect = (propCountry: string) => (event: any) => {
        console.log('event', event);
        const value = event?.value || event?.target?.value;

        const country = countries.find((i: any) => i.domicile === value)!;
        setGroupedData({
            ...setDataFromContext()!,
            countryCode: value as TCountryCode,
            countryId: country.domicileId!,
            countryName: country?.domicileName,
        });

        if (type === SelectionLevel.Asset) {
            setAssets(value);
        } else if (type === SelectionLevel.Investor) {
            setInvestors(value);
        }
    };

    const handleDelete = (index: number) => () => {
        const tmp = [...ungroupedData];
        tmp.splice(index, 1);

        setUngroupedData(tmp);

        if (!groupedData && tmp.length === 0) {
            setGroupedData(setDataFromContext());
        }
    };

    const getUngroupedFromGrouped = (allowEmpty = false) => {
        const groupedItems = (
            !groupedData
                ? []
                : 'items' in groupedData!
                ? groupedData.items.length
                    ? groupedData.items
                    : []
                : [groupedData.item]
        ).filter((item) => !!item.id);

        if (!allowEmpty && (!groupedItems.length || (groupedItems.length && !groupedItems[0].id))) return [];

        return [
            ...ungroupedData,
            ...groupedItems.map((item: GroupedSelectionItem | GroupedSelectionItemAsset) => {
                const tmp: SelectedItemExtended | SelectedItemAsset = {
                    id: item.id,
                    name: item.name,
                    assetAllocation: item.assetAllocation,
                    countryCode: groupedData!.countryCode,
                    countryName: groupedData!.countryName,
                    countryId: groupedData!.countryId,
                };

                if ('currentAssetTaxId' in item) {
                    (tmp as SelectedItemAsset).currentAssetTaxId = item.currentAssetTaxId;
                }

                return tmp;
            }),
        ];
    };

    const toggleGroupedData = () => {
        const groupedItems = !groupedData
            ? []
            : 'items' in groupedData!
            ? groupedData.items.length
                ? groupedData.items
                : []
            : [groupedData.item];

        if (!groupedItems.length || (groupedItems.length && !groupedItems[0].id)) return false;

        setUngroupedData([
            ...ungroupedData,
            ...groupedItems.map((item: GroupedSelectionItem | GroupedSelectionItemAsset) => {
                const tmp: SelectedItemExtended | SelectedItemAsset = {
                    id: item.id,
                    name: item.name,
                    assetAllocation: item.assetAllocation,
                    countryCode: groupedData!.countryCode,
                    countryName: groupedData!.countryName,
                    countryId: groupedData!.countryId,
                };

                if ('currentAssetTaxId' in item) {
                    (tmp as SelectedItemAsset).currentAssetTaxId = item.currentAssetTaxId;
                }

                return tmp;
            }),
        ]);

        return true;
    };

    const handleAddCountry = () => {
        toggleGroupedData();
        const grouped = setDataFromContext();
        if (grouped && 'item' in grouped) {
            if (!grouped.item) {
                // @ts-ignore
                grouped.item = {};
            }
            // @ts-ignore
            grouped.item.assetAllocation = 0;
        }
        setGroupedData(grouped);
    };

    const clearGroupedData = () => {
        setGroupedData(undefined);
    };

    const validateAssetAllocationChange = (i: number) => (value: number) => {
        const max = 100;

        let tmp = [...ungroupedData] as SelectedItemAsset[];

        const item = { ...tmp[i] };

        const currentValue = item.assetAllocation;
        value = Math.max(0, Math.min(max, +value));

        tmp[i].assetAllocation = value;

        const isGroupAvailable = groupedData && 'item' in groupedData && groupedData?.item?.id;

        let groupedTmp: undefined | GroupedSelectionSingle = undefined;

        if (isGroupAvailable) {
            groupedTmp = {
                ...groupedData,
            } as GroupedSelectionSingle;
        }

        let leftOvers = currentValue - value;

        let totalItems = ungroupedData.length - 1;
        if (isGroupAvailable) {
            totalItems += 1;
        }

        const items: any[] = [...tmp];

        if (groupedTmp) {
            items.push(groupedTmp.item);
        }

        const maxRounds = 20;
        let rounds = 0;
        while (rounds < maxRounds && leftOvers !== 0) {
            totalItems =
                leftOvers < 0
                    ? items.filter((item) => item.assetAllocation > 0).length - 1
                    : items.filter((item) => item.assetAllocation < 100).length - 1;

            const perItem = totalItems > 0 ? +(leftOvers / totalItems).toFixed(2) : leftOvers;

            // @ts-ignore
            for (const [index, item] of items.entries()) {
                if (leftOvers === 0) break;

                let substructed = 0;
                if (index !== i) {
                    if (leftOvers < 0 && item.assetAllocation + perItem < 0) {
                        substructed = -item.assetAllocation;
                        item.assetAllocation = 0;
                    } else if (leftOvers > 0 && item.assetAllocation + perItem > 100) {
                        substructed = 100 - item.assetAllocation;
                        item.assetAllocation = 100;
                    } else {
                        substructed = perItem;
                        item.assetAllocation = +(item.assetAllocation + perItem).toFixed(2);
                    }
                } else {
                    item.assetAllocation = value;
                }

                leftOvers =
                    leftOvers > 0
                        ? Math.max(0, +(leftOvers - substructed).toFixed(2))
                        : Math.min(+(leftOvers - substructed).toFixed(2), 0);
            }

            rounds++;
        }

        setUngroupedData(tmp);
        setGroupedData(groupedTmp);
    };

    const handleRowClick = (i: number) => (e: any) => {
        if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'BUTTON') {
            const ungrouped = getUngroupedFromGrouped(true);

            const item = ungroupedData[i];

            const base = {
                countryCode: item.countryCode,
                countryName: item.countryName,
                countryId: item.countryId,
            };

            if (isMulti) {
                let selectedItems: any[] = [];
                if (window.innerWidth <= 1919) {
                    selectedItems = [item];
                } else {
                    selectedItems = ungrouped.filter((ungrouped) => ungrouped.countryCode === item.countryCode);
                }

                setGroupedData({
                    ...base,
                    items: selectedItems,
                });

                setUngroupedData([
                    ...ungrouped.filter((ungrouped) => !selectedItems.find((item) => item.id === ungrouped.id)),
                ]);
            } else {
                const asset = ungrouped.find(
                    (ungrouped) => ungrouped.countryId === item.countryId && ungrouped.id === item.id
                )! as SelectedItemAsset;
                setGroupedData({
                    ...base,
                    item: asset,
                } as GroupedSelectionSingle);

                setUngroupedData(
                    ungrouped.filter(
                        (ungroupedItem) => ungroupedItem.countryId !== item.countryId || ungroupedItem.id !== item.id
                    )
                );
            }
        }
    };

    return (
        <SelectionLayoutLevelContainer type={type}>
            {ungroupedData.map((item, i) => (
                <SelectionRowCompact
                    key={i}
                    country={item.countryCode!}
                    value={item.name!}
                    assetAllocation={item.assetAllocation}
                    handleDelete={handleDelete(i)}
                    validateAssetAllocationChange={validateAssetAllocationChange(i)}
                    onClick={handleRowClick(i)}
                    isAllocationChangeable={
                        type === SelectionLevel.Asset &&
                        (ungroupedData.length > 1 || (groupedData && 'item' in groupedData && !!groupedData.item?.id))
                    }
                />
            ))}

            {groupedData && (
                <>
                    {'items' in groupedData ? (
                        <>
                            <div className={styles.checkboxRow}>
                                <SelectionRowContext.Provider
                                    value={{
                                        type: type,
                                        options: availableOptions,
                                        handleSelect: handleSelect,
                                        selectedOption: groupedData.items,
                                        countries: availableCountries,
                                        onCountrySelect: handleCountrySelect(''),
                                        selectedCountry: groupedData.countryCode,
                                        showCountries: true,
                                        inputType: 'checkbox',
                                    }}
                                >
                                    <SelectionCheckboxRow
                                        type={type}
                                        isSecondary={!!ungroupedData.length}
                                        afterRow={
                                            !!ungroupedData.length && (
                                                <DeleteButton onClick={clearGroupedData} size={'sm'} />
                                            )
                                        }
                                    />
                                </SelectionRowContext.Provider>
                            </div>

                            {!groupedData.items.length && (
                                <div className={styles.selectRow}>
                                    <SelectionRowContext.Provider
                                        value={{
                                            type: type,
                                            options: availableOptions,
                                            handleSelect: handleSelect,
                                            selectedOption: undefined,
                                            countries: availableCountries,
                                            onCountrySelect: handleCountrySelect(''),
                                            selectedCountry: groupedData.countryCode,
                                            showCountries: true,
                                            inputType: 'checkbox',
                                        }}
                                    >
                                        <SelectionRow
                                            type={type}
                                            isSecondary={!!ungroupedData.length}
                                            afterRow={
                                                !!ungroupedData.length && (
                                                    <DeleteButton onClick={clearGroupedData} size={'sm'} />
                                                )
                                            }
                                            // isMulti={true}
                                        />
                                    </SelectionRowContext.Provider>
                                </div>
                            )}

                            {groupedData.items.map((item, i) => (
                                <div key={i} className={styles.selectRow}>
                                    <SelectionRowContext.Provider
                                        value={{
                                            type: type,
                                            options: availableOptions,
                                            handleSelect: handleSelect,
                                            selectedOption: item,
                                            countries: availableCountries,
                                            onCountrySelect: handleCountrySelect(''),
                                            selectedCountry: groupedData.countryCode,
                                            showCountries: true,
                                            index: i,
                                        }}
                                    >
                                        <SelectionRow
                                            type={type}
                                            isSecondary={!!ungroupedData.length}
                                            afterRow={
                                                !!ungroupedData.length && (
                                                    <DeleteButton onClick={clearGroupedData} size={'sm'} />
                                                )
                                            }
                                            // isMulti={true}
                                        />
                                    </SelectionRowContext.Provider>
                                </div>
                            ))}
                        </>
                    ) : (
                        <SelectionRowContext.Provider
                            value={{
                                type: type,
                                options: availableOptions,
                                handleSelect: handleSelect,
                                selectedOption: groupedData.item?.id,
                                countries: availableCountries,
                                onCountrySelect: handleCountrySelect(''),
                                selectedCountry: groupedData.countryCode,
                                showCountries: true,
                            }}
                        >
                            <SelectionRow
                                type={type}
                                isSecondary={!!ungroupedData.length}
                                afterRow={
                                    !!ungroupedData.length && <DeleteButton onClick={clearGroupedData} size={'sm'} />
                                }
                            />
                        </SelectionRowContext.Provider>
                    )}
                </>
            )}

            <SelectionLayoutRow>
                <div>
                    <Button
                        type={'button'}
                        onClick={handleAddCountry}
                        secondary={true}
                        outlined={true}
                        disabledLight={true}
                        fullWidth={true}
                        disabled={!isGroupFilled}
                        before={<Icon icon={Icons.add} />}
                        align={'left'}
                    >
                        Add line
                    </Button>
                </div>

                <div />
                <div />
            </SelectionLayoutRow>
        </SelectionLayoutLevelContainer>
    );
};

export default SelectionPopupRowMulti;
