import styles from './styles.module.css';
import { DiagramDataTableShortItem } from '../../../../types/deal-taxes';

interface DealTaxesDetailRowProps {
    rowTitle: string;
    data: DiagramDataTableShortItem[];
    isActive: boolean;
    legendChange: any;
    isMultiAsset: boolean;
}

const DealTaxesDetailRow = ({ rowTitle, data, isActive, legendChange, isMultiAsset }: DealTaxesDetailRowProps) => {
    const handleTipEnter = (tip: any) => {
        legendChange(tip.legendId);
    };

    const handleMouseLeave = () => {
        legendChange(null);
    };

    return (
        <div className={`${styles.row} ${isActive ? styles.active : ''}`}>
            <h5 className={styles.rowTitle}>{rowTitle}</h5>
            <div className={styles.rowData}>
                {data.map((d: DiagramDataTableShortItem, index: number) => {
                    return (
                        <div
                            key={index}
                            className={styles.group}
                            onMouseEnter={() => handleTipEnter(d)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <h6 className={styles.groupTitle}>{d.tax}</h6>
                            <span className={styles.groupValue}>{d.taxRate}</span>
                            <span className={`${styles.groupValue} ${styles.groupValueText}`}>
                                {d.taxBase}
                                {d.legendId && <span className={styles.legend}>{d.legendId}</span>}
                            </span>
                            {isMultiAsset && (
                                <span className={`${styles.groupValue} ${styles.groupValueText} ${styles.valueBold}`}>
                                    {d.assetClasses.join('; ')}
                                </span>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DealTaxesDetailRow;
