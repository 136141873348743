import { AdditionalChecks } from './AdditionalChecks';
import { SelectionCombination } from '../../../components/SelectedCombination';
import styles from './styles.module.css';
import { Button } from '../../../components';
import React from 'react';
import { Info } from './Info';
import SelectionMenuBase from '../../../components/SelectionMenuBase/SelectionMenuBase';

const LegalStructureSelection = ({ handleCheckbox, selectedData, onEdit, active, setActive, hidden }: any) => {
    return (
        <>
            <SelectionMenuBase info={<Info />} active={active} setActive={setActive} hidden={hidden}>
                <div
                    style={{
                        marginTop: 180,
                    }}
                >
                    <SelectionCombination selectedData={selectedData} isOptimisation={false} hideFundRow={true} />

                    <div className={styles.btnContainer}>
                        <Button type={'button'} color={'neutral'} onClick={onEdit}>
                            Edit
                        </Button>
                    </div>
                </div>
                <div
                    style={{
                        marginBottom: 145,
                        width: 160,
                    }}
                >
                    <h4
                        style={{
                            color: '#000',
                            marginBottom: 20,
                            fontWeight: 400,
                        }}
                    >
                        Further selection:
                    </h4>
                    <AdditionalChecks handleCheckbox={handleCheckbox} selectedData={selectedData} />
                </div>
            </SelectionMenuBase>

            {/*<div>*/}
            {/*    <TransitionComponent inProp={!active} duration={100}>*/}
            {/*        <p className={styles.graphTip}>*/}
            {/*            /!*{comments ? `${comments.legendId} : ${comments.fullComments}` : ''}*!/*/}
            {/*            {legends*/}
            {/*                ? legends.map((l, i) => (*/}
            {/*                      <React.Fragment key={i}>*/}
            {/*                          {l} <br />*/}
            {/*                      </React.Fragment>*/}
            {/*                  ))*/}
            {/*                : ''}*/}
            {/*        </p>*/}
            {/*    </TransitionComponent>*/}
            {/*</div>*/}
        </>
    );
};

export default LegalStructureSelection;
