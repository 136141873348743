import { useContext } from 'react';
import { SelectionRowContext } from '../../../context';
import { SelectionLevelProps } from '../../../types/general';
import Select from '../../Select/Select';
import styles from '../SelectionPopupCountryRow/styles.module.css';

const SelectionSelect = ({ isMulti }: { isMulti?: boolean }) => {
    const { index, type, options, handleSelect, selectedOption, selectedCountry, placeholder } =
        useContext(SelectionRowContext);

    return (
        <>
            {/*
        <select
            onChange={handleSelect ? handleSelect(SelectionLevelProps[type].name) : undefined}
            value={selectedOption || ''}
            disabled={!selectedCountry}
        >
            <option value={''} disabled={true}>
                Select {SelectionLevelProps[type].labelKey === 'assetClass' ? 'asset type' : 'structure type'}
            </option>
            {/*  @ts-ignore  */}
            {/*{options[selectedCountry]?.map((if2: any, key: any) => {*/}
            {/*    return (*/}
            {/*        <option key={key} value={if2[SelectionLevelProps[type].valueKey]}>*/}
            {/*            {if2[SelectionLevelProps[type].labelKey]}*/}
            {/*        </option>*/}
            {/*    );*/}
            {/*})}*/}
            {/*</select>*/}
            <div className={styles.column}>
                <Select
                    isMulti={isMulti}
                    disabled={!selectedCountry}
                    options={selectedCountry ? options[selectedCountry] : []}
                    value={selectedOption}
                    onChange={handleSelect ? handleSelect(SelectionLevelProps[type].name, index) : undefined}
                    valueKey={SelectionLevelProps[type].valueKey}
                    labelKey={SelectionLevelProps[type].labelKey}
                    placeholder={SelectionLevelProps[type].placeholder}
                />
            </div>
        </>
    );
};

export default SelectionSelect;
