import { Checkbox } from '../../../../components';

interface AdditionalChecksProps {
    handleCheckbox: any;
    selectedData: any;
}

const AdditionalChecks = ({ handleCheckbox, selectedData }: AdditionalChecksProps) => {
    return (
        <>
            <Checkbox
                label={'self - managed'}
                value={true}
                checked={selectedData.selfManaged}
                onChange={handleCheckbox('selfManaged')}
                info={`A collective investment scheme (CIS) should be deemed self-managed when the management functions are performed by the governing body or any internal resource of the CIS.`}
            />
            <Checkbox
                label={'no fixed capital'}
                value={true}
                checked={selectedData.noFixedCapital}
                onChange={handleCheckbox('noFixedCapital')}
                info={`It’s a type of investment fund in Luxembourg that has a fixed capital. Its share capital can only be increased by the company’s shareholders (via amendment of the constitutional documents) and any changes to the capital must be notified and published.`}
            />
            <Checkbox
                label={'open-ended'}
                value={true}
                checked={selectedData.openEnded}
                onChange={handleCheckbox('openEnded')}
                info={`Collective investment scheme whose investors have a legal right to redeem or withdraw their shares or interest at least once per year.`}
            />
            <Checkbox
                label={'corporate'}
                value={true}
                checked={selectedData.corporate}
                onChange={handleCheckbox('corporate')}
                info={
                    <>
                        A corporation is an independent legal entity owned by its shareholders (Lux: Sàrl, SA, SCA;
                        Cayman: company, LLC, SPC).
                        <br />
                        Whereas a limited partnership is a partnership that is established by contract between one or
                        more unlimited partners (or general partners) and one or more limited partners (Lux: SCS, SCSp,
                        SCA; Cayman: LP).
                    </>
                }
            />
            <Checkbox
                label={'incl. ELTIF'}
                value={true}
                checked={selectedData.ifEltif}
                onChange={handleCheckbox('ifEltif')}
                info={<>European Long-Term Investment Fund</>}
            />
        </>
    );
};

export default AdditionalChecks;
