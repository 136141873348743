import TableHeader from '../components/TableHeader';
import { Tr } from '../components';

import { BooleanFormat } from '../formats';

import { useTableStore } from '../../../store/useTableStore';

const SD3Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd3 ? (
        <table>
            <TableHeader sdKey={'sd3'} />
            <tbody>
                <Tr featName={'Legal form'} dataKey={'sd3'} colKey={'legalForm'} />

                <Tr featName={'Short Selling'} dataKey={'sd3'} colKey={'shortSelling'} formatFunc={BooleanFormat} />
                <Tr featName={'Borrowing'} dataKey={'sd3'} colKey={'borrowing'} formatFunc={BooleanFormat} />
                <Tr featName={'Risk diversification'} dataKey={'sd3'} colKey={'riskDiversificationText'} />
            </tbody>
        </table>
    ) : null;
};

export default SD3Table;
