import TableHeader from '../components/TableHeader';
import { Tr } from '../components';
import { BooleanFormat, DescriptionFormat, DescriptionProgressFormat, PeopleFormat, PercentFormat } from '../formats';
import TrProvider from '../components/TrProvider';
import { useTableStore } from '../../../store/useTableStore';

const SD1Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd1 ? (
        <table>
            <TableHeader sdKey={'sd1'} />

            <tbody>
                <Tr featName={'Legal form'} dataKey={'sd1'} colKey={'legalForm'} />

                <Tr featName={'Full name'} dataKey={'sd1'} colKey={'fullName'} />

                <Tr featName={'Governing law'} dataKey={'sd1'} colKey={'governingLaw'} />

                <Tr featName={'Domicile'} dataKey={'sd1'} colKey={'domicileName'} />

                <Tr featName={'Legal type'} dataKey={'sd1'} colKey={'legalType'} />

                <Tr featName={'Regulation type'} dataKey={'sd1'} colKey={'regulationType'} />

                <Tr featName={'Regulation name'} dataKey={'sd1'} colKey={'regulationName'} />

                <TrProvider>
                    <Tr
                        featName={'Level of regulation'}
                        dataKey={'sd1'}
                        colKey={'levelOfRegulation'}
                        progressCol={'levelOfRegulationBar'}
                        formatFunc={DescriptionProgressFormat}
                    />
                </TrProvider>

                <Tr
                    featName={'Alternative investment fund?'}
                    dataKey={'sd1'}
                    colKey={'ifAif'}
                    formatFunc={BooleanFormat}
                />

                <TrProvider>
                    <Tr
                        featName={'Qualification as AIF'}
                        dataKey={'sd1'}
                        formatFunc={DescriptionFormat}
                        colKey={'qualificationAsAif'}
                    />
                </TrProvider>

                <TrProvider>
                    <Tr
                        featName={'European Long-Term Investment Fund'}
                        dataKey={'sd1'}
                        formatFunc={BooleanFormat}
                        colKey={'ifEltif'}
                    />
                </TrProvider>

                <Tr
                    featName={'Has legal personality?'}
                    dataKey={'sd1'}
                    colKey={'ifHasLegalPersonality'}
                    formatFunc={BooleanFormat}
                />

                <Tr
                    featName={'General partner has limited liability?'}
                    dataKey={'sd1'}
                    colKey={'ifGpHasLimitedLiability'}
                    formatFunc={BooleanFormat}
                />

                <Tr
                    featName={'Shareholder or limited partner has limited liability?'}
                    dataKey={'sd1'}
                    colKey={'ifShareholderOrLpHasLimitedLiability'}
                    formatFunc={BooleanFormat}
                />

                <Tr featName={'Self managed?'} dataKey={'sd1'} colKey={'ifSelfManaged'} formatFunc={BooleanFormat} />

                <Tr
                    featName={'Min number of directors for fund vehicle or its local GP'}
                    dataKey={'sd1'}
                    colKey={'minNumDirectorsCompanyOrGp'}
                    formatFunc={PeopleFormat}
                />

                <Tr
                    featName={'Thin capitalization'}
                    dataKey={'sd1'}
                    colKey={'thinCapitalization'}
                    formatFunc={PercentFormat}
                />
            </tbody>
        </table>
    ) : null;
};

export default SD1Table;
