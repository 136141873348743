import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

import styles from './deal-taxes.module.css';

import axios from '../../utils/axios';

import { Button, Icon, Response } from '../../components';
import { DealTaxesDetails } from './components/DealTaxesDetails';
import { Sankey } from '../../components/Sankey/components';
import { SelectionPopup } from '../../components/SelectionPopup';
import { ResultTable, ResultTableLeft, ResultTableRight } from '../../components/ResultTable';
import { generateSankey } from '../../utils';
import {
    SelectedDataFund,
    SelectedItemAsset,
    SelectedItemExtended,
    SelectedItemLegalType,
} from '../../components/SelectionPopup/SelectionPopup';
import { useDiagramStore } from '../../store/useDiagramStore';
import camelcaseKeys from 'camelcase-keys';
import TransitionComponent from '../../components/TransitionComponent/TransitionComponents';
import LoaderComponent from '../../components/TransitionComponent/LoaderContent';
import { DealTaxesTips } from './components/DealTaxesTips';
import { Icons } from '../../components/Icon/Icon';
import {
    CorporateLegalTypeIds,
    DealTaxesCheckboxes,
    isDealTaxesCheckboxAvailable,
} from '../../utils/deal-taxes-checkboxes';
import { LinkedButton } from '../../components/Button';
import getDealTaxesUrlParams from '../../utils/get-deal-taxes-url-params';
import { GraphTypes } from '../../types/general';
import { DiagramData, DiagramDataResponse, DiagramDataTableShortItem, SankeyData } from '../../types/deal-taxes';
import DealTaxesAsideMenu from './components/DealTaxesAsideMenu';
import { DealTaxesInfo } from './components/DealTaxesInfo';

export interface DealTaxesSelectedData {
    selectedInvestor: SelectedItemExtended | undefined;
    assets: SelectedItemAsset[];
    investors: SelectedItemExtended[];
    investmentFund: SelectedItemLegalType;
    fundOfFunds: undefined | SelectedItemLegalType;

    openEnded: boolean;
    // corporate: boolean;
    booked?: boolean;
    ifBusinessAssets?: boolean;
    ifCommercialPartnership?: boolean;
    fofOpenEnded?: boolean;
    // fofCorporate?: boolean;
    fofIfCommercialPartnership?: boolean;
    ukInvesting?: boolean;
    fofUkInvesting?: boolean;
    // for optimisation
    fundStatus?: string;
}

const DealTaxes = () => {
    const [selectedData, setSelectedData] = useState<DealTaxesSelectedData>({
        selectedInvestor: undefined,
        assets: [],
        investors: [],

        investmentFund: {
            id: undefined,
            name: undefined,
            countryCode: undefined,
            countryName: undefined,
            countryId: undefined,
            legalTypeId: undefined,
            legalFormId: undefined,
            legalFormShort: undefined,
        },
        fundOfFunds: undefined,

        openEnded: false,
        // corporate: false,
        booked: undefined,
        ifBusinessAssets: undefined,
        ifCommercialPartnership: undefined,
        fofOpenEnded: undefined,
        // fofCorporate: undefined,
        fofIfCommercialPartnership: undefined,

        ukInvesting: undefined,
        fofUkInvesting: undefined,

        // for tax optimisation
        // fundStatus: undefined,
    });

    const [setAssets, setInvestmentFunds, setInvestors, getCountries, setFundOfFunds, setGraphStoreData] =
        useDiagramStore((state) => [
            state.setAssets,
            state.setInvestmentFunds,
            state.setInvestors,
            state.getCountries,
            state.setFundOfFunds,
            state.setStoreData,
        ]);

    const [isFoF, setIsFoF] = useState(false);

    const [pageReady, setPageReady] = useState(false);
    const [loading, setLoading] = useState(false);

    const [initialInvestment, setInitialInvestment] = useState(100);
    const [returnOnAsset, setReturnOnAsset] = useState(20);
    const [distribution, setDistribution] = useState(10);

    const [rawData, setRawData] = useState<DiagramData | null>(null);

    const [error, setError] = useState<boolean>(false);

    const [showPopup, setShowPopup] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [sankeyData, setSankeyData] = useState<SankeyData | null>(null);

    const [activeCategory, setActiveCategory] = useState<string | undefined | null>(null);

    const [checkClicked, setCheckClicked] = useState(false);
    const [toggleRequest, setToggleRequest] = useState(false);

    const [tableUrl, setTableUrl] = useState('');
    const [optimisationUrl, setOptimisationUrl] = useState('');

    const [legend, setLegend] = useState(null);
    const [isOptimisation, setIsOptimisation] = useState(false);

    const [legends, setLegends] = useState<any>([]);

    const navigation = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [fund, setFund] = useState({
        country: '',
        fund: '',
    });

    const [fofLegalTypesDisabled, setFofLegalTypesDisabled] = useState<boolean | undefined>(undefined);
    const [fofOpenEndedDisabled, setFofOpenEndedDisabled] = useState<boolean | undefined>(undefined);

    const [isFoFExpanded, setIsFoFExpanded] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(true);

    const [graphHeight, setGraphHeight] = useState(700);
    const graphRef = useRef(null);

    useEffect(() => {
        setIsMenuOpen(!showPopup);
    }, [showPopup]);

    useEffect(() => {
        setIsFoF(!!selectedData.fundOfFunds);
    }, [selectedData.fundOfFunds]);

    useEffect(() => {
        if (!searchParams.toString().length) {
            (async () => {
                await initPage();
            })();
        }
    }, [searchParams]);

    useEffect(() => {
        setGraphStoreData({
            useInvestmentStructure: true,
            graphType: GraphTypes.Taxes,
        });
        (async () => {
            await initPage();
        })();
    }, []);

    useEffect(() => {
        if (toggleRequest) {
            (async () => await getSankeyData(true))();
        }
    }, [toggleRequest]);

    useEffect(() => {
        // if (checkClicked && selectedData.assets.length && selectedData.investors.length && selectedData.currentFund) {
        if (checkClicked && selectedData.assets.length && selectedData.investors.length) {
            (async () => await getSankeyData(false))();
        }
    }, [selectedData.openEnded, selectedData.booked, checkClicked]);

    useEffect(() => {
        if (showPopup) {
            setLoading(true);
            setPageReady(true);
        }
    }, [showPopup]);

    const initPage = async () => {
        await getCountries();

        const { data, optimisation, initialInvestmentParam, returnOnAssetParam, distributionParam, openTaxIndex } =
            await getDealTaxesUrlParams(searchParams);

        setIsOptimisation(optimisation === 'true');

        if (!data) {
            setShowPopup(true);
            return;
        }

        // optimisation
        if (optimisation && initialInvestmentParam && returnOnAssetParam && distributionParam) {
            setInitialInvestment(+initialInvestmentParam);
            setReturnOnAsset(+returnOnAssetParam);
            setDistribution(+distributionParam);

            setFund({
                country: data.investmentFund.countryCode!,
                fund: data.investmentFund.name!,
            });

            setOptimisationUrl(
                new URLSearchParams({
                    initialInvestment: initialInvestmentParam,
                    returnOnAsset: returnOnAssetParam,
                    distribution: distributionParam,
                    // currentInvestor: data.currentInvestor,
                    // investorCountry: data.investorCountry,
                    // assetCountry: data.assetCountry,
                    // currentAsset: data.currentAsset,
                    openTaxIndex: openTaxIndex ? openTaxIndex : '',
                }).toString()
            );
        }

        for (const item of data.investors) {
            setInvestors(item.countryCode!);
        }
        for (const item of data.assets) {
            setAssets(item.countryCode!);
        }
        setInvestmentFunds(data.investmentFund.countryCode!);

        if (data.fundOfFunds) {
            setFundOfFunds(data.fundOfFunds.countryCode!);
        }

        setSelectedData({
            ...selectedData,
            ...data,
        });

        setCheckClicked(true);
        setPageReady(true);
    };

    const handleCheckbox = (prop: any) => async (event: any) => {
        const checked = event.target.checked;
        const tmp = { ...selectedData, [prop]: checked };

        setUrlParams(tmp);

        setSelectedData({ ...tmp });

        setCheckClicked(true);
    };

    const handleFormSubmission = async (formSelectedData: SelectedDataFund) => {
        // if (!sankeyData) {
        // setLoading(true);
        // }

        // const tmp = {
        //     ...selectedData,
        // };
        //
        // if (!formSelectedData.currentInvestor || !formSelectedData.currentAsset || !formSelectedData.currentFund) {
        //     return;
        // }
        //
        // if ([5, 6, 7, 8].indexOf(+formSelectedData.currentInvestor) > -1) {
        //     tmp.booked = true;
        // }
        //
        // tmp.ifBusinessAssets = [14, 15, 16].indexOf(+formSelectedData.currentInvestor) > -1;
        //
        // tmp.fofCorporate = [8, 16].indexOf(+formSelectedData.currentInvestor) > -1;

        setShowDetails(false);
        setActiveCategory(null);
        setIsFoFExpanded(false);

        setSelectedData({
            // ...tmp,
            ...selectedData,
            ...formSelectedData,
            selectedInvestor: formSelectedData.investors[0],
            // fundStatus: undefined,
        });
        setShowPopup(false);
        setToggleRequest(true);
    };

    const getOpenEnded = async (currentFund: number, legalTypeId: number) => {
        const { data } = await axios.get('/base/check/taxes-open-ended', {
            params: {
                currentFund,
                legalTypeId,
            },
        });

        console.log({
            getOpenEnded: data,
        });

        return data;
    };

    const getFoFOpenEnded = async (currentFoF: number, legalTypeId: number) => {
        const { data } = await axios.get('/base/check/fof-open-ended', {
            params: {
                currentFoF,
                legalTypeId,
            },
        });

        return data;
    };

    const setTipsLegendId = (tips: DiagramDataTableShortItem[]) => {
        const filteredFullComments = tips.filter((t) => t.textComments !== '');

        const uniqueFullComments = Array.from(new Set(filteredFullComments.map((i) => i.textComments))).map((i) => {
            return filteredFullComments.find((c) => c.textComments === i);
        });

        // TODO: ???
        // @ts-ignore
        for (const [i, uc] of uniqueFullComments.entries()) {
            uc.legendId = i === 0 ? '*' : i.toString();
        }

        for (const uc of filteredFullComments) {
            if (!uc.legendId) {
                const sameComment = uniqueFullComments.find((t) => t!.textComments === uc.textComments);
                uc.legendId = sameComment!.legendId;
            }
        }
    };

    const setUrlParams = (selectedData: DealTaxesSelectedData) => {
        const tableUrl = new URLSearchParams();

        for (const entry of Object.entries(selectedData)) {
            // @ts-ignore
            if (typeof entry[1] !== 'undefined' && entry[1] !== 'undefined') {
                tableUrl.set(entry[0], JSON.stringify(entry[1]));
            }
        }

        if (isOptimisation) {
            tableUrl.set('optimisation', `${isOptimisation}`);
            tableUrl.set('initialInvestment', `${initialInvestment}`);
            tableUrl.set('returnOnAsset', `${returnOnAsset}`);
            tableUrl.set('distribution', `${distribution}`);
            // tableUrl.set('investmentFund', `${fund.fund}`);
        }

        // tableUrl.set(
        //     'investorCountryId',
        //     findOption({
        //         options: countriesStore,
        //         selectedOption: selectedData.investorCountry,
        //         key: 'domicile',
        //     })?.domicileId || ''
        // );
        // tableUrl.set(
        //     'assetCountryId',
        //     findOption({
        //         options: countriesStore,
        //         selectedOption: selectedData.assetCountry,
        //         key: 'domicile',
        //     })?.domicileId || ''
        // );
        // tableUrl.set(
        //     'investmentFundCountryId',
        //     findOption({
        //         options: fundCountries,
        //         selectedOption: selectedData.investmentFundCountry,
        //         key: 'domicile',
        //     })?.domicileId || ''
        // );

        // if (!selectedData.currentFoF) {
        //     tableUrl.delete('currentFoF');
        //     tableUrl.delete('fofCountry');
        //     tableUrl.delete('fofIfCommercialPartnership');
        // }

        // tableUrl.forEach((value, name) => {
        //     if (typeof value === 'undefined' || value === 'undefined') {
        //         tableUrl.delete(name);
        //     }
        // });

        setTableUrl('?' + tableUrl.toString());

        if (!isOptimisation) {
            setSearchParams(tableUrl.toString());

            return;
        }
    };

    const verifyCheckboxValues = async (changeCheckboxValue: boolean) => {
        const isCorporate = CorporateLegalTypeIds.includes(selectedData.investmentFund.legalTypeId!);
        let isOpenEnded = selectedData.openEnded;

        let ifCommercialPartnership = selectedData.ifCommercialPartnership;

        let fofOpenEnded = selectedData.fofOpenEnded;
        let fofIfCommercialPartnership = selectedData.fofIfCommercialPartnership;
        const fofCorporate = CorporateLegalTypeIds.includes(selectedData.fundOfFunds?.legalTypeId!);

        let ifBusinessAssets = selectedData.ifBusinessAssets;
        let booked = selectedData.booked;

        let ukInvesting = selectedData.ukInvesting;
        let fofUkInvesting = selectedData.fofUkInvesting;

        if (!isDealTaxesCheckboxAvailable(DealTaxesCheckboxes.ifBusinessAssets, selectedData)) {
            ifBusinessAssets = undefined;
            if (typeof booked === 'undefined') {
                booked = true;
            }
        } else {
            booked = undefined;
            if (typeof ifBusinessAssets === 'undefined' || [14, 15].includes(selectedData.selectedInvestor?.id!)) {
                ifBusinessAssets = true;
            }
        }

        if (!isDealTaxesCheckboxAvailable(DealTaxesCheckboxes.ukInvesting, selectedData)) {
            ukInvesting = undefined;
        } else {
            if (typeof ukInvesting === 'undefined') {
                ukInvesting = true;
            }
        }

        console.log('selectedData.fundOfFunds', selectedData.fundOfFunds);
        console.log('OTHER', changeCheckboxValue, typeof fofOpenEndedDisabled === 'undefined');

        if (!!selectedData.fundOfFunds) {
            if (changeCheckboxValue || typeof fofOpenEndedDisabled === 'undefined') {
                const fofCheck = await getFoFOpenEnded(
                    selectedData.fundOfFunds.id!,
                    selectedData.fundOfFunds.legalTypeId!
                );

                console.log({
                    fofCheck,
                });

                const fofLTDisabled =
                    fofCheck.filter(
                        (value: any, index: number, self: any) =>
                            self.map((v: any) => v.fofLegalType).indexOf(value.fofLegalType) === index
                    ).length === 1;

                setFofLegalTypesDisabled(fofLTDisabled);

                const fofOEDisabled =
                    fofCheck.filter(
                        (value: any, index: number, self: any) =>
                            self.map((v: any) => v.fofIfOpenEnded).indexOf(value.fofIfOpenEnded) === index
                    ).length === 1;

                setFofOpenEndedDisabled(fofOEDisabled);

                if (changeCheckboxValue) {
                    // fofCorporate = fofCheck[1]?.legalType.trim() === 'corporate';
                    fofOpenEnded = fofCheck[0]?.fofIfOpenEnded;
                }
            }
        } else {
            fofOpenEnded = undefined;
            fofIfCommercialPartnership = undefined;
            // fofCorporate = undefined;
            fofUkInvesting = undefined;
        }

        console.log({
            changeCheckboxValue,
        });

        if (changeCheckboxValue) {
            const openEnded = await getOpenEnded(
                selectedData.investmentFund.id!,
                selectedData.investmentFund.legalTypeId!
            );

            // isCorporate = openEnded[0]?.legalType.trim() === 'corporate';
            isOpenEnded = openEnded[0]?.ifOpenEnded;

            if (
                isDealTaxesCheckboxAvailable(DealTaxesCheckboxes.ukInvesting, selectedData) &&
                (typeof ukInvesting !== 'undefined' || (isCorporate && isOpenEnded))
            ) {
                ukInvesting = true;
            }

            if (
                isDealTaxesCheckboxAvailable(DealTaxesCheckboxes.fofUkInvesting, selectedData) &&
                (typeof fofUkInvesting !== 'undefined' || (fofCorporate && fofOpenEnded))
            ) {
                fofUkInvesting = true;
            }
        }

        if (isDealTaxesCheckboxAvailable(DealTaxesCheckboxes.ifCommercialPartnership, selectedData)) {
            if (typeof ifCommercialPartnership === 'undefined' || isCorporate) {
                ifCommercialPartnership = true;
            }
        } else {
            ifCommercialPartnership = undefined;
        }

        if (isDealTaxesCheckboxAvailable(DealTaxesCheckboxes.fofIfCommercialPartnership, selectedData)) {
            if (typeof fofIfCommercialPartnership === 'undefined' || fofCorporate) {
                fofIfCommercialPartnership = true;
            }
        } else {
            fofIfCommercialPartnership = undefined;
        }

        console.log('CHECKS', {
            booked,
            ifBusinessAssets,
            // corporate: isCorporate,
            openEnded: isOpenEnded,
            ifCommercialPartnership,
            fofOpenEnded,
            fofIfCommercialPartnership,
            // fofCorporate,
            ukInvesting,
            fofUkInvesting,
        });

        return {
            booked,
            ifBusinessAssets,
            // corporate: isCorporate,
            openEnded: isOpenEnded,
            ifCommercialPartnership,
            fofOpenEnded,
            fofIfCommercialPartnership,
            // fofCorporate,
            ukInvesting,
            fofUkInvesting,
        };
    };

    const getSankeyData = async (changeCheckboxValue: boolean) => {
        // if (!selectedData.assets.length) return;

        setToggleRequest(false);
        setCheckClicked(false);

        const checkboxes = await verifyCheckboxValues(changeCheckboxValue);

        const tmp = {
            ...selectedData,
            ...checkboxes,
        };

        setSelectedData(tmp);

        console.log('tmp', tmp);

        try {
            const { data } = await axios.get<DiagramDataResponse>('/deal-taxes/diagram', {
                params: {
                    ...tmp,
                },
            });

            const sankey = generateSankey(camelcaseKeys(data.result));

            setTipsLegendId(data.result.popUpTable.flat());
            setRawData(camelcaseKeys(data.result));
            setSankeyData(sankey);
            setLegends(sankey.tips.map((tip) => tip.data).flat(1));
        } catch (e) {
            setTipsLegendId([]);
            setRawData(null);
            setSankeyData(null);
            setLegends([]);
        } finally {
            setUrlParams(tmp);

            // setTimeout(() => {
            setLoading(false);
            // }, 100);
        }
    };

    const savePreset = async (e: any) => {
        e.preventDefault();

        try {
            await axios.post('/presets/deal-taxes', selectedData);

            // redirect
            navigation('/account?save=1');
        } catch (e) {
            setError(true);
        }
    };

    const onDataChange = (category: any) => {
        setActiveCategory(category);
        if (category) {
            // @ts-ignore
            setGraphHeight(graphRef?.current?.clientHeight || graphHeight);
            setShowDetails(true);
        } else {
            setShowDetails(false);
        }
    };

    const handleDetailsClose = () => {
        setShowDetails(false);
        setActiveCategory(null);
    };

    const onLegendChange = (legendId: any) => {
        setLegend(legendId);
    };

    const validateSelection = async (data: SelectedDataFund) => {
        if (
            !data.investors.filter((i) => i.id).length ||
            !data.assets.filter((i) => i.id).length ||
            !data.investmentFund?.id ||
            (data.fundOfFunds && !data.fundOfFunds.id)
        )
            return true;

        console.log('validateSelection', data);

        // for (const investor of data.investors) {
        //     for (const asset of data.assets) {
        //         if (investor.id && asset.id) {
        await axios.get(`/base${data.fundOfFunds ? '/fof' : ''}/check/selection`, {
            params: {
                currentInvestors: data.investors
                    .filter((i) => i.id)
                    .map((i) => i.id)
                    .flat(),
                assetClasses: data.assets
                    .filter((i) => i.id)
                    .map((a) => a.name)
                    .flat(),
                currentAssetTaxIds: data.assets
                    .filter((i) => i.id)
                    .map((a) => a.currentAssetTaxId)
                    .flat(),
                currentFund: data.investmentFund?.id,
                currentFoF: data.fundOfFunds?.id,
            },
        });
        // }
        // }
        // }
        return true;
    };

    const setSelectedInvestor = (investor: SelectedItemExtended) => {
        setSelectedData({
            ...selectedData,
            selectedInvestor: investor,
        });

        setToggleRequest(true);
    };

    return (
        <>
            <TransitionComponent inProp={showPopup} isAbsolute={true} duration={100}>
                <SelectionPopup isMulti={true} onSubmit={handleFormSubmission} validateSelection={validateSelection} />
            </TransitionComponent>

            <LoaderComponent inProp={pageReady} hideLoader={true}>
                <ResultTable>
                    <ResultTableLeft
                        tableInfo={
                            isOptimisation && (
                                <DealTaxesInfo
                                    data={rawData?.nav}
                                    initialInvestment={initialInvestment}
                                    returnOnAsset={returnOnAsset}
                                    distribution={distribution}
                                />
                            )
                        }
                        comments={legend ? legends.find((l: any) => l.legendId === legend) : null}
                        showComments={!isMenuOpen && !!legend}
                        onEdit={() => {
                            setShowPopup(true);
                        }}
                        isOptimisation={isOptimisation}
                        selectedData={selectedData}
                        fundData={fund}
                    >
                        {!isOptimisation && (
                            <DealTaxesAsideMenu
                                infoProps={{
                                    data: rawData?.nav,
                                    initialInvestment: initialInvestment,
                                    returnOnAsset: returnOnAsset,
                                    distribution: distribution,
                                    inAside: true,
                                }}
                                checksProps={{
                                    handleCheckbox: handleCheckbox,
                                    selectedData: selectedData,
                                    isFoF: isFoF,
                                    fofLegalTypesDisabled: fofLegalTypesDisabled,
                                    fofOpenEndedDisabled: fofOpenEndedDisabled,
                                }}
                                isOptimisation={isOptimisation}
                                selectedData={selectedData}
                                setSelectedInvestor={setSelectedInvestor}
                                active={isMenuOpen}
                                setActive={setIsMenuOpen}
                                hidden={showPopup}
                                onEdit={() => {
                                    setIsMenuOpen(false);
                                    setShowPopup(true);
                                }}
                            />
                        )}
                    </ResultTableLeft>

                    <div>
                        <LoaderComponent inProp={!loading}>
                            <div className={styles.graph}>
                                <div className={`${isFoFExpanded ? 'fof-active' : ''} ${styles.graphContainer}`}>
                                    {sankeyData && sankeyData.links && (
                                        <>
                                            <Sankey
                                                graphRef={graphRef}
                                                sankey={sankeyData}
                                                activeCategory={activeCategory}
                                                onDataChange={onDataChange}
                                                onLegendChange={onLegendChange}
                                            />

                                            {isFoF && (
                                                <div className={styles.expandBtn}>
                                                    <Button
                                                        onClick={() => {
                                                            setIsFoFExpanded(!isFoFExpanded);
                                                        }}
                                                        outlined={true}
                                                        before={
                                                            !isFoFExpanded && (
                                                                <span style={{ width: '10px' }}>
                                                                    <Icon icon={Icons.zoom} />
                                                                </span>
                                                            )
                                                        }
                                                        after={
                                                            isFoFExpanded && (
                                                                <span style={{ width: '10px' }}>
                                                                    <Icon icon={Icons.close} />
                                                                </span>
                                                            )
                                                        }
                                                    >
                                                        {isFoFExpanded ? 'Expanded' : 'Expand'}
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>

                                {/*{!isFoF && !isOptimisation && (*/}
                                <div className={styles.actions}>
                                    {isFoF && (
                                        <p className={styles.tip}>Click on the visualization to explore the details</p>
                                    )}

                                    <LinkedButton
                                        to={{
                                            pathname: '/tax-review',
                                            search: tableUrl,
                                        }}
                                    >
                                        Learn more
                                    </LinkedButton>

                                    <Button onClick={savePreset}>Print review</Button>

                                    {isOptimisation && (
                                        <LinkedButton
                                            to={{
                                                pathname: '/tax-optimisation',
                                                search: searchParams.toString(),
                                            }}
                                        >
                                            Back to options
                                        </LinkedButton>
                                    )}
                                </div>
                            </div>
                        </LoaderComponent>
                    </div>

                    {/*{rawData && (*/}
                    <ResultTableRight
                        showDetails={showDetails}
                        details={
                            <DealTaxesDetails
                                activeCategory={activeCategory}
                                tips={rawData?.popUpTable?.flat() || []}
                                onClose={handleDetailsClose}
                                legendChange={onLegendChange}
                                height={graphHeight}
                                isMultiAsset={selectedData.assets.length > 1}
                            />
                        }
                        isFoF={isFoF}
                        isOptimisation={isOptimisation}
                        tips={<DealTaxesTips />}
                    />
                    {/*)}*/}
                </ResultTable>
            </LoaderComponent>

            {error && (
                <Response type={'error'} aligned={false} setResponse={setError}>
                    An error occurred.
                </Response>
            )}
        </>
    );
};

export default DealTaxes;
