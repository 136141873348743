import TableHeader from '../components/TableHeader';
import { Tr } from '../components';

import { BooleanFormat, DescriptionProgressFormat } from '../formats';

import { useTableStore } from '../../../store/useTableStore';
import TrProvider from '../components/TrProvider';

const SD10Table = ({}) => {
    const [data] = useTableStore((state) => [state.data]);

    return data && data.sd10 ? (
        <table>
            <TableHeader sdKey={'sd10'} />

            <tbody>
                <Tr featName={'Legal form'} dataKey={'sd10'} colKey={'legalForm'} />
                <Tr
                    featName={'Disclosure of ultimate beneficial ownership'}
                    dataKey={'sd10'}
                    colKey={'disclosureOfUbo'}
                    formatFunc={BooleanFormat}
                />
                <Tr
                    featName={'Disclosure of shareholders identity'}
                    dataKey={'sd10'}
                    colKey={'disclosureOfShrldIdentity'}
                    formatFunc={BooleanFormat}
                />
                <TrProvider>
                    <Tr
                        featName={'Public dislosures'}
                        dataKey={'sd10'}
                        colKey={'publicDisclosures'}
                        formatFunc={DescriptionProgressFormat}
                        progressCol={'publicDisclosuresBar'}
                    />
                </TrProvider>
            </tbody>
        </table>
    ) : null;
};

export default SD10Table;
